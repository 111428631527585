import * as React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './PanelModal.css'
import {Tooltip, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Tree, TreeNode } from 'react-organizational-chart';
import awsimage from '../../images/aws_icon.png';
import azureimage from '../../images/azure_icon.png';
import gcpimage from '../../images/gcp_icon.png';


// const StyledNode = styled.div`
//   padding: 5px;
//   border-radius: 8px;
//   display: inline-block;
//   border: 1px solid red;
// `;

const capitalizeFirst = str => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
const caps = str => {
    return str.split('.').map(capitalizeFirst).join(' ');
  };

const backdrop = {
    visible : { opacity: 1 },
    hidden : { opacity: 0 }
}

const modal = {
    hidden: {
        y: "-100vh",
        opacity: 0
    },
    visible: {
        y: "70px",
        opacity: 1,
        transition: { delay: 0.5 }
    }
}

const Modal = ({showModal, setShowModal, interviewerData}) => {
    // const { user} = useAuthenticator((context) => [context.user]);
    return (
        <AnimatePresence exitBeforeEnter>
            {showModal && (
                <motion.div className='backdrop'
                    variants={backdrop}
                    initial="hidden"
                    animate="visible" 
                    exit="hidden" 
                >
                    <motion.div className='modal' 
                    variants={modal}
                    >
                    { interviewerData !== "" && 
                        <div className='wrapper'>
                            <Tooltip title="Close">
                                <CloseIcon style={{color:"#FE414D",position:'absolute',right:'15px', top:'15px', cursor:'pointer'}} onClick={() => {setShowModal(false)}}/>
                            </Tooltip>

                            <div>
                                <h1>Interviewer Profile</h1>
                            </div>
                           
                            <div className='interviewer-profile_wrapper'>
                                <div className='interviewer-name_component'>
                                    <Typography style={{fontWeight:'bold'}}>Interviewer Name</Typography>
                                    <Typography style={{marginLeft:'20px'}}>{caps(interviewerData.interviewerName)}</Typography>
                                </div>
                                <div className='interviewer-expertise_component'>
                                    <Typography style={{fontWeight:'bold', marginTop:'20px'}}>Interviewer Expertise</Typography>
                                    <div className='interviewer-expertise_icons'> 
                                                {interviewerData.interviewerExpertise.map((doc)=>{
                                                    return(
                                                        <React.Fragment>
                                                            {doc === 'AWS' && <img alt="AWS Certified" src={awsimage} style={{maxWidth:"150px",minWidth:"48px",height:"48px"}}/>}
                                                            {doc === 'Azure' && <img alt="Azure Certified" src={azureimage} style={{maxWidth:"150px",minWidth:"48px",height:"48px"}}/>}
                                                            {doc === 'GCP' && <img alt="GCP Certified" src={gcpimage} style={{maxWidth:"150px",minWidth:"48px",height:"48px"}}/>}
                                                        </React.Fragment>
                                                    )
                                                })}
                                    </div>
                                </div>
                            </div>
                                    
                            <div>
                                <Typography style={{fontWeight:'bold', marginTop:'30px', marginBottom:'20px'}}>Allowed Roles for {caps(interviewerData.interviewerName)}</Typography>
                            </div>
                                    <React.Fragment>
                                        <Tree lineWidth={'3px'}
                                              lineColor={'#68B984'}
                                              lineBorderRadius={'10px'}
                                              label={<div className='role-tree_parent'>Role Type Allowed</div>}>
                                                {interviewerData.roleTypeAllowed.map((doc)=>{
                                                    return(
                                                        <TreeNode label={<div className='role-tree_child'>{doc}</div>}>
                                                        </TreeNode>
                                                    )
                                                })}
                                                
                                              </Tree>
                                    </React.Fragment>
                            
                        </div> }
                        {interviewerData === "" && <h1>No Data Available</h1>}
                    </motion.div>
                </motion.div>

            )}

        </AnimatePresence>
    )
}

export default Modal;