import {createSlice,createAsyncThunk } from "@reduxjs/toolkit"


let initialState=localStorage.getItem('myString') || "CnD";
const profileSlice=createSlice({
    name:'profile',
    initialState,
    reducers:{
        setProfile:(state,action)=>{
            return action.payload;
        }
    }
})
export const {setProfile}=profileSlice.actions
export default profileSlice.reducer;