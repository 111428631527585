import { Redirect,Route} from "react-router-dom";
import { useAuthenticator } from '@aws-amplify/ui-react';

  export const AdminRoute = (props) => {
    const { user} = useAuthenticator((context) => [context.user]);
    return user.signInUserSession.idToken.payload['cognito:groups']!==undefined && user.signInUserSession.idToken.payload['cognito:groups'].includes("Admin") ? (
      <Route {...props} />
    ) : (
         <Redirect
        to={{
          pathname: "/unauthorised",
        }}
      />
    );
  };
  export const InterviewerRoute = (props) => {
    const { user} = useAuthenticator((context) => [context.user]);

    return user.signInUserSession.idToken.payload['cognito:groups']!==undefined && user.signInUserSession.idToken.payload['cognito:groups'].includes("Interviewer") ? (
      <Route {...props} />
    ) : (
         <Redirect
        to={{
          pathname: "/unauthorised",
        }}
      />
    );
  };
  export const CXORoute = (props) => {
    const { user} = useAuthenticator((context) => [context.user]);

    return user.signInUserSession.idToken.payload['cognito:groups']!==undefined && user.signInUserSession.idToken.payload['cognito:groups'].includes("CXO") ? (
      <Route {...props} />
    ) : (
         <Redirect
        to={{
          pathname: "/unauthorised",
        }}
      />
    );
  };
  export const CXORouteAndInterviewerRoute = (props) => {
    const { user} = useAuthenticator((context) => [context.user]);

    return user.signInUserSession.idToken.payload['cognito:groups']!==undefined && (
      user.signInUserSession.idToken.payload['cognito:groups'].includes("CXO") ||user.signInUserSession.idToken.payload['cognito:groups'].includes("Interviewer") )  ? (
      <Route {...props} />
    ) : (
         <Redirect
        to={{
          pathname: "/unauthorised",
        }}
      />
    );
  };
  export const ProtectedRoute = (props) => {
    const { user} = useAuthenticator((context) => [context.user]);

    return user.signInUserSession.idToken.payload['cognito:groups']!==undefined ? (
      <Route {...props} />
    ) : (
         <Redirect
        to={{
          pathname: "/unauthorised",
        }}
      />
    );
  };

  