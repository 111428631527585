import * as React from 'react';
import { useSelector } from "react-redux"
import "../styles/Block.css"

function BlockKeyPointsReview(props) {
    

    const pointTiles=useSelector((store)=>{
        // console.log(store.adminform.rows)
        return (store.block.variants[props.index].expectations.map((text,index) => {
                return(
                    <li>
                        {text}
                    </li>
 
                )
            }))
    })
    return(
        <div style={{display:"flex",flexDirection:"column"}}>
            <div className='block-keypoints-div' style={{height:"100%"}}>
                <div className="block-keypoints-header" style={{textAlign:"left"}}>
                    <div className='block-keypoints-title'  style={{textAlign:"left"}}> {props.role}</div>
                </div>

                <ol className="block-keypoints-tiles">
                    {pointTiles}
                </ol>

                {pointTiles.length===0 && 
                <div className="block-keypoints-tiles">
                    <p style={{color:"grey",fontSize:"x-large",margin:"auto"}}>No Expectations Added</p>
                </div>
                }
            </div>
        </div>

  )

}

export default BlockKeyPointsReview;