import * as React from 'react';
// import { useSelector } from "react-redux"
import "../styles/Block.css"
function BlockKeyPointReview(props) {
    
        return(
            <li>
            {props.point}
            </li>
        )

}

export default BlockKeyPointReview;