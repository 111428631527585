
import { useSelector } from "react-redux";
import { sendForm } from "../features/blockSlice";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "@mui/material";
import { useAuthenticator } from '@aws-amplify/ui-react';

function BlockSubmit(params) {
    const { user} = useAuthenticator((context) => [context.user]);

    const history=useHistory();

    const dispatch=useDispatch()
    const data=useSelector((store)=>{
        // console.log(store.block)
        return store.block;
    })
    return(
        <Button variant="contained" style={{width:"100px",margin:"auto",marginTop:"70px"}} onClick={()=>{
            dispatch(sendForm({"data":data,"jwtToken":user.signInUserSession.idToken.jwtToken}))
            history.push("/sections")
        }}> Submit </Button>
    )
}
export default BlockSubmit;