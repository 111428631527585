import {createSlice} from "@reduxjs/toolkit"


const initialState={
    rows:[
        {
            skills:"Jenkins/Azure DevOps",
            framework:["Continuous Integration","Build Automation","Pipeline as Code","Change Management","Version Control","Code Quality Analysis"],
            positions:[
                {
                    name:"Associate",
                    traits:[
                        "Understands build automation process using Ant, Maven, Gradle etc.",
                        "Can explain the build server setup & pipeline orchestration setup",
                        "Hands-on experience code compilation in Java, .Net etc.",
                        "Hands-on experience code compilation in Java, .Net etc.",
                        "Understands build automation process using Ant, Maven, Gradle etc.",
                        "Can explain the build server setup & pipeline orchestration setup",
                        "Hands-on experience code compilation in Java, .Net etc.",
                        "Understands build automation process using Ant, Maven, Gradle etc.",
                        "Can explain the build server setup & pipeline orchestration setup",
                        "Hands-on experience code compilation in Java, .Net etc."
                    ]
                },
                {
                    name:"Associate",
                    traits:[
                        "Understands build automation process using Ant, Maven, Gradle etc.",
                        "Can explain the build server setup & pipeline orchestration setup",
                        "Hands-on experience code compilation in Java, .Net etc.",
                        "Hands-on experience code compilation in Java, .Net etc."
                    ]
                },            {
                    name:"Associate",
                    traits:[
                        "Understands build automation process using Ant, Maven, Gradle etc.",
                        "Can explain the build server setup & pipeline orchestration setup",
                        "Hands-on experience code compilation in Java, .Net etc.",
                        "Hands-on experience code compilation in Java, .Net etc."
                    ]
                },            {
                    name:"Associate",
                    traits:[
                        "Understands build automation process using Ant, Maven, Gradle etc.",
                        "Can explain the build server setup & pipeline orchestration setup",
                        "Hands-on experience code compilation in Java, .Net etc.",
                        "Hands-on experience code compilation in Java, .Net etc."
                    ]
                },            {
                    name:"Associate",
                    traits:[
                        "Understands build automation process using Ant, Maven, Gradle etc.",
                        "Can explain the build server setup & pipeline orchestration setup",
                        "Hands-on experience code compilation in Java, .Net etc.",
                        "Hands-on experience code compilation in Java, .Net etc."
                    ]
                }
            ]
        },
        {
            skills:"Jenkins/Azure DevOps",
            framework:["Continuous Integration","Build Automation","Pipeline as Code","Change Management","Version Control","Code Quality Analysis"],
            positions:[
                {
                    name:"Associate",
                    traits:[
                        "Understands build automation process using Ant, Maven, Gradle etc.",
                        "Can explain the build server setup & pipeline orchestration setup",
                        "Hands-on experience code compilation in Java, .Net etc.",
                        "Hands-on experience code compilation in Java, .Net etc."
                    ]
                },
                {
                    name:"Associate",
                    traits:[
                        "Understands build automation process using Ant, Maven, Gradle etc.",
                        "Can explain the build server setup & pipeline orchestration setup",
                        "Hands-on experience code compilation in Java, .Net etc.",
                        "Hands-on experience code compilation in Java, .Net etc."
                    ]
                },            {
                    name:"Associate",
                    traits:[
                        "Understands build automation process using Ant, Maven, Gradle etc.",
                        "Can explain the build server setup & pipeline orchestration setup",
                        "Hands-on experience code compilation in Java, .Net etc.",
                        "Hands-on experience code compilation in Java, .Net etc."
                    ]
                },            {
                    name:"Associate",
                    traits:[
                        "Understands build automation process using Ant, Maven, Gradle etc.",
                        "Can explain the build server setup & pipeline orchestration setup",
                        "Hands-on experience code compilation in Java, .Net etc.",
                        "Hands-on experience code compilation in Java, .Net etc."
                    ]
                },            {
                    name:"Associate",
                    traits:[
                        "Understands build automation process using Ant, Maven, Gradle etc.",
                        "Can explain the build server setup & pipeline orchestration setup",
                        "Hands-on experience code compilation in Java, .Net etc.",
                        "Hands-on experience code compilation in Java, .Net etc."
                    ]
                }
            ]
        }
    ]

};
const details=
    {
        skills:"Jenkins/Azure DevOps",
        framework:["Continuous Integration","Build Automation","Pipeline as Code","Change Management","Version Control","Code Quality Analysis"],
        positions:[
        ]
    }

const adminformSlice=createSlice({
    name:'form',
    initialState,
    reducers:{
        addRow:(state,action)=>{
            state.rows.push(details);
        },
        removeRow:(state,action)=>{
            console.log(action.payload.index)
            const index=action.payload.index;
            if (index > -1) {
                state.rows.splice(index, 1);
              }
              
        },
        addColumn:(state,action)=>{
            const role=action.payload.role
            const column_to_be_added={
                "name":role,
                "traits":[]
            }
            state.rows.map((row)=>{
                return row.positions.push(column_to_be_added)
            })
        },
        removeColumn:(state,action)=>{
            const index=action.payload.index

            state.rows.map((row)=>{
                return row.positions.splice(index, 1)
            })
        }
    }
});

export const {addRow,removeRow,addColumn,removeColumn}=adminformSlice.actions
export default adminformSlice.reducer