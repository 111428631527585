import * as React from 'react';
import { useSelector } from "react-redux"
import "../styles/Block.css"
import { TextField } from '@mui/material';
import BlockKeyPoint from './BlockKeyPoint';
import BlackButton from './BlackButton';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch } from 'react-redux';
import {addKeyPoint} from "../features/blockSlice"
import ClearIcon from '@mui/icons-material/Clear';
import { removeRole } from '../features/blockSlice';
function BlockKeyPoints(props) {
    const dispatch=useDispatch();
    const [point, setpoint] = React.useState('');
    const handleChangeText = (event) => {
            setpoint( event.target.value);
        // console.log(point)

      };
    const pointTiles=useSelector((store)=>{
        // console.log(store.adminform.rows)
        return (store.block.variants[props.index].expectations.map((text,index) => {
                return(
                    <BlockKeyPoint key = {index} roleindex={props.index} pointindex={index} point={text}/>
                )
            }))
    })
    return(
        <div style={{display:"flex",flexDirection:"column",width:"100%"}}>
            <div className='block-keypoints-div' style={{width:"100%",marginTop:"0px"}}>
                <div className="block-keypoints-header">
                    <div className='block-keypoints-title'> {props.role}</div>
                    {/* <ClearIcon className="block-keypoints-clear" style={{width:"50px",marginLeft:"auto"}}
                    onClick={()=>{
                        dispatch(removeRole({"index":props.index}))
                    }}/> */}
                </div>
                <div className="block-keypoints-tiles">
                    {pointTiles}
                </div>
                {pointTiles.length===0 && 
                <div className="block-keypoints-tiles">
                <p style={{color:"grey",fontSize:"x-large",margin:"auto"}}>No Expectations are Added</p>
                </div>
                }
                <TextField value={point} id="outlined-basic" label="Write Point" variant="outlined" style={{marginTop:"30px"}} fullWidth onChange={handleChangeText}
                onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                        const variable= {"index":props.index,"text":point}
                        setpoint("")
                        dispatch(addKeyPoint(variable))
                      }

                }}
                />
            </div>
            <BlackButton style={{width:"100px"}} variant="contained" startIcon={<AddIcon />} onClick={()=>{
                const variable= {"index":props.index,"text":point}
                setpoint("")
                dispatch(addKeyPoint(variable))
                
            }}>
            Add
            </BlackButton>
        </div>

  )

}

export default BlockKeyPoints;