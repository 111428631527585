import React from 'react';
import '../styles/CreateForm.css';
import CreateFormRow from "./CreateFormRow";
import { useSelector } from "react-redux"
import AddRowCol from './AddRowCol';
function CreateForm() {
      const rows=useSelector((store)=>{
        // console.log(store.adminform.rows)
        return (store.adminform.rows.map((rowdata,index) => {
                return(
                    <CreateFormRow key={index} index={index} data={rowdata}/>
                )
            }))
    })
    return(
        <div className='createform'>
        <AddRowCol/>
        <h1 style={{textAlign:"center"}}> Cloud and Devops form</h1>
        <div style={{display:"flex",flexDirection:"Column",gap:"80px"}}>
            {rows}
        </div>
        
        </div>

    )
  }
  export default CreateForm;