// import BlockSection from "./BlockSection";
// import BlockSubSection from "./BlockSubSection";
// import BlockAddRole from "./BlockAddRole";
import BlockKeyPoints from "./BlockKeyPoints";
import "../styles/Block.css";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import BlockProbeAreas from "./BlockProbeAreas";
import RoleButton from "./RoleButton";
import BlockReview from "./BlockReview";
import { motion } from "framer-motion";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import BlockSubmit from "./BlockSubmit";

import Loader from "./Loader"

import List from '@mui/material/List';
import {Divider} from "@mui/material";
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

function BlockEdit() {
    const [pageNumber,setPageNumber]=useState(0);
    const isLoading=useSelector((store)=>{
        // console.log(store.adminform.rows)
        return (store.block.isLoading)
    })
    const sectionName=useSelector((store)=>{
        return store.block.section
    })
    const subSectionName=useSelector((store)=>{
        return store.block.subSection
    })
    const roleTiles=useSelector((store)=>{
        // console.log(store.adminform.rows)
        return (store.block.variants.map(({role,expectations},index) => {
                return(
                    <RoleButton key={index} index={index} role={role}/>
                    
                )
            }))
    })
    const keyPointsTiles=useSelector((store)=>{
        // console.log(store.adminform.rows)
        return (store.block.variants.map(({role,expectations},index) => {
                if(store.block.displayingindex===index){
                    return(
                        <BlockKeyPoints key={index} role={role} index={index}/>

                    )
                }
                else{
                    return(
                        <></>
                    )
                }

            }))
    })
    const backgroundColors=["#ffe63b","#ffe63b","#ffe63b"]  
    // const backgroundColors=["#00e6c3","#00e6c3","#00e6c3"]  

    return(
        <div >
            {isLoading && <Loader/>}
            {!isLoading &&
            <div>
                <div className="block-buttons-div" style={{backgroundColor:backgroundColors[pageNumber]}}>
                    {pageNumber===1 && <Button  startIcon={<ArrowBackIosIcon/>} variant="text" style={{height:"50px",fontWeight:"bold",color:"#000"}} onClick={()=>{
                            if(pageNumber>=1){
                                setPageNumber(pageNumber-1); 
                            }
                            
                        }}>Back</Button>}
                    
                    {pageNumber===0 && <Button endIcon={<ArrowForwardIosIcon/>} style={{height:"50px",fontWeight:"bold",color:"#000"}} variant="text" onClick={()=>{
                    setPageNumber(pageNumber+1);  
                    }}>Review</Button>}
                </div>
                <div>
                    <div className="block-pages">
                        {pageNumber===0 && 
                            <motion.div  initial={{ x: -200 }} animate={{ x: 0 }} transition={{ type: "spring", stiffness: 100 }} className="blockform-page-1">
                                <div className="block-selectors-row">
                                    <div className="block-selectors-col">
                                        <p className='block-heading'>Section</p>
                                        <p>{sectionName}</p>
                                    </div>
                                    <div  className="block-selectors-col">
                                        <p className='block-heading'>Sub-Section</p>
                                        <p>{subSectionName}</p>
                                    </div>
                                </div>
                                <BlockProbeAreas/>
                        
                            </motion.div>
                        }
                        
                    
                        {pageNumber===0 && 
                        <motion.div  initial={{ x: -200 }} animate={{ x: 0 }} transition={{ type: "spring", stiffness: 100 }} className="blockform-page-2">

                                {/* <Box sx={{ width: '100%', maxWidth: 280, bgcolor: 'background.paper',boxShadow:" rgba(0, 0, 0, 0.24) 0px 3px 8px" }} style={{height:"100%"}}>   */}

                                    <List className="role-displayer">
                                        <ListItem>
                                            <ListItemButton>
                                                <ListItemIcon>
                                                    <ArrowDownwardIcon style={{color:'rgb(254, 65, 77)'}}/>
                                                </ListItemIcon>
                                                        <ListItemText primary="Navigate Through your Roles" style={{color:'rgb(254, 65, 77)'}} />
                                            </ListItemButton>
                                        </ListItem>
                                        <Divider/>

                                        {roleTiles}
                                    </List>
                                {/* </Box> */}

                            <div className="block-keypoints"style={{width:"50%"}}>
    
                                    {keyPointsTiles}                        

                            </div>
                        </motion.div>}
                        {pageNumber===1 && !isLoading && 
                        <motion.div  initial={{ x: -200 }} animate={{ x: 0 }} transition={{ type: "spring", stiffness: 100 }} className="block-review-page">
                            <BlockReview />
                            <BlockSubmit/>
                        
                        </motion.div>
                        }
                    </div>
                </div>

            </div>}
        </div>
    )
}

export default BlockEdit;
