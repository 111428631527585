import React from 'react';
import '../styles/CreateFormRow.css';
import CreateFormColumn from "../Components/CreateFormColumn"
import { IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch } from 'react-redux'
import { removeRow } from '../features/adminFormSlice';

function CreateFormRow(props){
    const dispatch=useDispatch();
    const columnboxes=props.data.positions.map(position=>{
        console.log(position)
        return (
            <CreateFormColumn nameOfColumn={position.name} traits={position.traits}/>
        )
    })
    return (
        <div className="create-form-row">
            <div className='create-form-row-indiacator'>
                <div className='create-form-row-number-box'>
                    <h3>Row : {props.index+1}</h3>
                </div>
                <div ClassName="icon-box" style={{Color:"red",marginLeft:"auto",height:"fitContent",background:"red"}}>
                    <IconButton aria-label="delete" size="large" onClick={()=>{
                        dispatch(removeRow({index:props.index}))
                    }}>
                        <ClearIcon />
                    </IconButton>
                </div>

            </div>

            <div className="create-form-row-title">
                <h3>Continuous deployment</h3>
            </div>
            <div className='create-form-row-columns-box'>
                <CreateFormColumn nameOfColumn={"framework"} traits={[props.data.skills]}/>
                <CreateFormColumn nameOfColumn={"skills"} traits={props.data.framework}/>
                {columnboxes}
            </div>
        </div>
    )
}
export default CreateFormRow;