import "../styles/BlocksDisplayer.css"

import { useEffect, useState } from "react";
import axios from "axios";
import BlockDisplay from "./BlockDisplay";
import { Link } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import Loader from "./Loader";
import { useDispatch } from "react-redux";
import { setToInitialState } from "../features/blockSlice";
import { proxy } from "../proxy";
import { Tooltip } from "@mui/material";
import Fab from "@mui/material/Fab";
import { useAuthenticator } from '@aws-amplify/ui-react';

function BlocksDisplayer(props) {
    const { user} = useAuthenticator((context) => [context.user]);
    const dispatch=useDispatch();
    const [data,setData]=useState([]);
    const [isLoading,setisLoading]=useState(true);   
    useEffect(() => {
        axios.get(`${proxy}/api/buildingblocks`, {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              'Authorization':user.signInUserSession.idToken.jwtToken,
              "Content-Type": "application/json",
              "Access-Control-Allow-Credentials": true,
            },
          }).then((res) => {
                setisLoading(false);

            //   console.log("result",res.data.result);
              setData(res.data.result)
            //   console.log("data",data);
          }).catch((error)=>{
            setisLoading(false);

            alert("error")
          });
           // eslint-disable-next-line
      }, []);
    return(
        <div>
            {isLoading && <Loader/>}
            {!isLoading && <div className="blocks-displayer-box">
                <div className="sections-header" style={{backgroundColor:"#ffe63b",height:"75px"}}>
                    <div className="sections-title-header" >
                        <p>Building Blocks </p>
                    </div>
                </div>
                <div className="blocks-displayer-box" style={{marginTop:"25px"}}>
                    {data.map(({_id,subSection,section})=>{
                        return( <BlockDisplay key={_id} blockId={_id} section={section} subSection={subSection} />)
                    })}
                </div>
                <Link to="/block" style={{textDecoration:"none",color:"white",backgroundColor:"#aeea00"}} onClick={()=>{
                                    dispatch(setToInitialState())
                }}>
                    <Tooltip title="Add Building Block">
                        <Fab backgroundColor="#aeea00" aria-label="add" style={  {position: 'fixed',bottom:16,right:16,backgroundColor:"#000"}}>
                                <AddIcon style={{color:"white"}}/>    
                        </Fab>
                    </Tooltip>
                </Link>

                </div>

            }

        </div>

    )
}
export default BlocksDisplayer;