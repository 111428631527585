import { Checkbox, FormControlLabel, FormGroup, TableCell } from '@mui/material';
import * as React from 'react';


const CheckboxGroup = (props) => {

    const[Checked, setChecked] = React.useState([]);

    let CLOUD_TYPES = ['AWS','Azure','GCP'];

    const handleCheckbox = (expertise) => {
        const currentIndex = Checked.indexOf(expertise);
        const newChecked = [...Checked];
    
        if (currentIndex === -1) {
          newChecked.push(expertise);
        } else {
          newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
        props.handleFilters(newChecked);
    }


    return (
        <React.Fragment>
            {CLOUD_TYPES.map((value,index)=>{
                return(
                    <TableCell align="center">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={value}
                              onChange={() => handleCheckbox(value)}
                              checked={Checked.indexOf(value) === -1 ? false : true}
                            />
                          }
                          label={value}
                        />
                      </FormGroup>
                    </TableCell>
                )
            })}
        </React.Fragment>

    )
}

export default CheckboxGroup;