import React, { useState } from "react";
// import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import TemplateDisplay from "./TemplateDisplay";
import { Input, Tooltip } from "@mui/material";
import Fab from "@mui/material/Fab";
import { useAuthenticator } from "@aws-amplify/ui-react";
import Loader from "./Loader";
import { proxy } from "../proxy";
import { setToInitialState } from "../features/templateSlice";
import { useDispatch } from "react-redux/es/exports";
import { useSelector } from "react-redux";

function TemplatesDisplayer() {
  const { user } = useAuthenticator((context) => [context.user]);
  const dispatch = useDispatch();
  const [isLoading, setisLoading] = useState(true);
  const [data, setData] = useState([]);
  const profile= useSelector((state) => {
    return state.profile;
  });
  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${proxy}/api/template_titles/${profile}`, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          Authorization: user.signInUserSession.idToken.jwtToken,

          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      })
      .then((res) => {
        setisLoading(false);
        // console.log(user)
        setData(res.data.result);
        //   console.log("data",data)
      })
      .catch((error) => {
        setisLoading(false);
        console.log(error);
      });

    // eslint-disable-next-line
  }, [profile]);
  const templateTiles = data.map(
    ({ _id, templateName, approvalStatus, comments }, index) => {
      return (
        <TemplateDisplay
          key={index}
          templateName={templateName}
          mongoid={_id}
          approvalStatus={approvalStatus}
          comments={comments}
        />
      );
    }
  );
  return (
    <div>
      {isLoading && <Loader />}
      {!isLoading && (
        <div className="sections-div">
          <div
            className="sections-header"
            style={{ background: "#fe414d", color: "#fff" }}
          >
            <div className="sections-title-header">
              <p style={{ color: "#fff" }}>Templates </p>
            </div>
          </div>

          <div
            className="sections-tiles-displayer"
            style={{ alignItems: "center" }}
          >
            {templateTiles}
          </div>

          <Link
            to="/addtemplate/new"
            style={{
              textDecoration: "none",
              color: "white",
              backgroundColor: "#aeea00",
            }}
            onClick={() => {
              dispatch(setToInitialState());
            }}
          >
            <Tooltip title="Add Template">
              <Fab
                backgroundColor="#aeea00"
                aria-label="add"
                style={{
                  position: "fixed",
                  bottom: 16,
                  right: 16,
                  backgroundColor: "#000",
                }}
              >
                <AddIcon style={{ color: "white" }} />
              </Fab>
            </Tooltip>
          </Link>
        </div>
      )}
    </div>
  );
}

export default TemplatesDisplayer;
