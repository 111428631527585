import * as React from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import axios from "axios";
import "../../styles/UpdateInterviewerPage.css";
import BlackButton from "../BlackButton";
import { proxy } from "../../proxy";
import { useHistory } from "react-router-dom";
import PeopleIcon from "@mui/icons-material/People";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import CreateIcon from "@mui/icons-material/Create";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { tidy, distinct,filter } from '@tidyjs/tidy'
import GradingIcon from '@mui/icons-material/Grading';
import { Fab, Tooltip } from '@mui/material';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2'
import CandidateModal from './CandidateModal';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Autosave, useAutosave } from 'react-autosave';
import {
  TextField,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Box,
  FormControlLabel,
  RadioGroup,
  Radio,
  ToggleButtonGroup,
  ToggleButton,
  Autocomplete,
  Button,
} from "@mui/material";
import { useSelector } from "react-redux";

function PhoneInterviewPage() {
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
    const { formId } = useParams();
    const [roleData, setRoleData] = useState([]);
  const [role, setRole] = useState("");
  const [status,setStatus]=useState(false);
  const [feedback,setFeedback]=useState("");
  const [AOI,setAOI]=useState("");
  const history = useHistory();
  const { user } = useAuthenticator((context) => [context.user]);
  const [showCandidateModal, setCandidateModal] = useState(false);
  const [autosave, setAutosave] = React.useState(true);
  const profile= useSelector((state) => {
    return state.profile;
  });
    useEffect(() => {
        // setMaxDate(moment(new Date()).format("YYYY-MM-DD"));
        window.scrollTo(0, 0);
        axios
          .get(`${proxy}/api/role`, {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              Authorization: user.signInUserSession.idToken.jwtToken,
    
              "Access-Control-Allow-Credentials": true,
            },
          })
          .then((res) => {
            setRoleData(res.data.result);
          })
          .catch((error) => {
            console.log(error);
          });

        axios
        .get(`${proxy}/api/candidatedetails/${formId}`, {
            withCredentials: true,
            headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: user.signInUserSession.idToken.jwtToken,

            "Access-Control-Allow-Credentials": true,
            },
        })
        .then((res) => {
            // console.log(res.data.result);
            setRole(res.data.result.role);
            setFeedback(res.data.result.feedback);
            setAOI(res.data.result.AreasOfImprovement)
        })
        .catch((error) => {
            console.log(error);
        });
      }, []);
    
      const handleChangeRole = (event) => {
        console.log(formId);
        setRole(event.target.value);
      };
      const handleChangeToggle= (event) =>{
        console.log(status);
        setStatus(event.target.value);
      };

      const handleAreaOfImprovements = (event) =>{
        setAOI(event.target.value);
      };

      const handleFeedback = (event) =>{
        setFeedback(event.target.value);
      }

      const onSubmit = (e) =>{
        e.preventDefault();
        let s;
        let a=true;
        console.log(status);
        if(status==="true")
        {
            s="Qualified"
        }
        else
        {
            s="Disqualified"
        }
        sendReportsData({
            formId: formId,
            feedback: feedback,
            preferredRole: role,
            areasOfImprovement: AOI,
            status: s,
            formFilledStatus: true,
          });

      }
      const handleAutosaveClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setAutosave(false);
      };
      const autoSaveSectionData = async() => {
        // await timeout(3000);
        if(autosave===false)
        {
        try {
          axios
      .post(`${proxy}/api/autosavesectiondata`,{"formId": formId,
        "feedback": feedback,
        "preferredRole": role,
        "areasOfImprovement": AOI,}, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          Authorization: user.signInUserSession.idToken.jwtToken,
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      })
        //   didSave();
          setAutosave(true);
      } catch (err) {        
          console.log(err);
      }
    }
    }
      const sendReportsData = (data) =>{
        console.log(data);
        axios
      .put(`${proxy}/api/candidatedetails`, data, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          Authorization: user.signInUserSession.idToken.jwtToken,
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      })
      .then((res) => {
        // console.log(res.data.result);
        // console.log("Incoming Data ===>" + res.data.result._id)
        history.push(`/`);
        setAutosave(true);

      })
      .catch((error) => {
        console.log(error);
      });
        
      }


    return (
        <div>
          <Snackbar open={autosave} autoHideDuration={5000} onClose={handleAutosaveClose}>
        <Alert onClose={handleAutosaveClose} severity="success" sx={{ width: '100%' }}>
          Autosaved Successfully at {new Date().toLocaleTimeString()} !!
        </Alert>
      </Snackbar>

      <div className="sections-header" style={{background:"#fe414d",color:"#fff"}}>
                <div className="sections-title-header" >
                    <div className='interview-icon' style={{display: "inline-block" ,alignItems:"left",marginTop:"36px",marginRight:"30rem"}}>
                      <Tooltip title="Candidate Details" >
                          <PeopleIcon  onClick={()=>{setCandidateModal(true)}} style={{width:"100%",height:"60%",marginTop:"5px",cursor:'pointer'}}/>
                      </Tooltip>
                    </div>
                    <div style={{display: "inline-block",top:"50%",marginRight:"32rem",marginBottom:"24px"}}> 
                        <p style={{color:"#fff"  , fontSize:"20px"}}>Phone Interview </p>
                    </div>
                  </div>
              </div>
            <Grid
                container
                rowSpacing={0.25}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={3}>
                  <div className="interviewer-form-option-title">
                    <p>
                      <PeopleIcon
                        style={{ color: "red", marginRight: "5px" ,marginLeft:"20px"}}
                      ></PeopleIcon>
                      Feedback :
                    </p>
                  </div>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    label="Feedback"
                    placeholder="Enter Feedback"
                    variant="outlined"
                    value={feedback}
                    onChange={handleFeedback}
                    name="fullName"
                    multiline
                    rows={10}
                    sx={{width: "98%" ,marginTop:"10px", marginRight:"50px"}}
                  />
                  <Autosave data={feedback} onSave={autoSaveSectionData} />
                </Grid>
                <Grid item xs={3}>
                  <div className="interviewer-form-option-title">
                    <p>
                      <PeopleIcon
                        style={{ color: "red", marginRight: "5px" ,marginLeft:"20px" }}
                      ></PeopleIcon>
                      Areas of Improvement  :
                    </p>
                  </div>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    label="Areas of Improvement"
                    placeholder="Enter Areas of Improvement"
                    variant="outlined"
                    value={AOI}
                    onChange={handleAreaOfImprovements}
                    name="fullName"
                    multiline
                    rows={2}
                    sx={{ width: "98%" ,marginTop: "10px" ,marginRight:"20px"}}
                  />
                  <Autosave data={AOI} onSave={autoSaveSectionData} />
                </Grid>
                <Grid item xs={3}>
                  <div></div>
                </Grid>
                <Grid item xs={2}>
                  <div className="candidate-form-option-title">
                    <p>
                      <CreateIcon
                        style={{ color: "red" }}
                      ></CreateIcon>
                      Qualified
                    </p>
                  </div>
                  <FormControl fullWidth>
                  <ToggleButtonGroup
                    color="primary"
                    value={status}
                    exclusive
                    onChange={handleChangeToggle}
                    aria-label="Platform"
                    
                  >
                    <ToggleButton style={{ marginTop: "9.5px" }} value="true">Yes</ToggleButton>
                    <ToggleButton style={{ marginTop: "9.5px" }} value="false">No</ToggleButton>
                  </ToggleButtonGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <div className="candidate-form-option-title">
                    <p>
                      <CreateIcon
                        style={{ color: "red"}}
                      ></CreateIcon>
                      Role
                    </p>
                  </div>
                  <FormControl fullWidth >
                    <InputLabel id="demo-simple-select-label">
                      Preferred Role
                    </InputLabel>
                    <Select
                      label="Choose Role"
                      name="role"
                      value={role}
                      onChange={handleChangeRole}
                      sx={{marginTop:"10px"}}
                    >
                      {roleData.map(({ _id, role }, index) => {
                        return (
                          <MenuItem key={index} value={role}>
                            {role}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  <Autosave data={feedback} onSave={autoSaveSectionData} />
                    {/* <FormHelperText>{errors.role?.message}</FormHelperText> */}
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                <BlackButton sx={{ width: "90%", backgroundColor:"#1666ff", marginTop:"5rem"}} type="submit" onClick={onSubmit}>
                  Submit
                </BlackButton>
              </Grid>
            </Grid>
            
            <CandidateModal showCandidateModal={showCandidateModal} setCandidateModal={setCandidateModal} formid = { formId }/>
            
        </div>
    );
}
export default PhoneInterviewPage;