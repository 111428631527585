import { useAuthenticator } from '@aws-amplify/ui-react';
import { TextField } from "@mui/material";
import "../styles/SectionSubSectionEditor.css"
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useParams } from "react-router-dom";
import BlackButton from "./BlackButton";
import { useState } from "react";
import Loader from "./Loader"
import axios from "axios";
import { proxy } from "../proxy";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
function SubSectionAdder(){
    const history = useHistory();
    const { user} = useAuthenticator((context) => [context.user]);
    const { section,sectionid } = useParams();
    const [subsectionName,setSubSectionName]=useState('');
    const [isLoading,setIsLoading]=useState(false)
    async function  senddata(){
        document.getElementById('section-submit').disabled=true;
        axios.post(`${proxy}/api/subsection`,{"_id":sectionid,"subSection":`${subsectionName}`}, {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              'Authorization':user.signInUserSession.idToken.jwtToken,
              "Access-Control-Allow-Credentials": true,
            },
          }).then((res) => {
                setIsLoading(false);

            //   console.log(res.data);
              alert("Subsection Added")
              history.push('/sections')
          }).catch((error)=>{
            setIsLoading(false);
            
            alert("error")
            console.log(error)
          });
          document.getElementById('section-submit').disabled=false;
          setIsLoading(true);
    }
    return(
        <div style={{width:"100%"}}>
            {!isLoading && 
            <div>
                <div className="sections-header">
                    <div className="sections-title-header">
                        <p>Add Your Data here <ArrowDownwardIcon/> </p>
                    </div>
                </div>
                <div className="section-editor-components">
                    <div className="section-editor-component">
                        <div className="section-editor-component-title">
                            <p>Current Section</p>
                        </div>
                        <TextField fullWidth disabled value={section}/>
                    </div>
                    <div className="section-editor-component">
                        <div className="section-editor-component-title">
                            <p>Add Your New SubSection</p>
                        </div>
                        <TextField fullWidth onChange={(e)=>setSubSectionName(e.target.value)}/>
                    </div>
                    <BlackButton id="section-submit" variant="contained" style={{marginTop:"10px"}} onClick={()=>{
                                senddata()
                            }}> Submit
                    </BlackButton>
                </div>
            </div>}
            {isLoading && <Loader/>}
                        
        </div>

    )
}
export default SubSectionAdder;