import React from 'react';
import '../styles/CreateFormColumn.css';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
// import AddIcon from '@mui/icons-material/Add';
import Traits from './Traits';
function CreateFormColumn(props){
    const traitlist=props.traits.map((trait,index)=>{
        return (
            <Traits trait={trait}>
            </Traits>
        )
    })
    return(
        <div className='create-form-column'>
            <div className='create-form-column-heading-box'>
                <p>{props.nameOfColumn}</p>
            </div>
            <div className='create-form-column-content-box'>
                <ol>
                {traitlist}
                </ol>
            </div>
            <div className='create-form-column-inputs'>
                <TextField id="filled-basic" label="Write your Point" variant="outlined" size='large' style={{width:"80%"}} />
                <Button variant="contained"  onClick={()=>{
                    // dispatch(addRow());
                }}>+</Button>
            </div>

        </div>
    )
}
export default CreateFormColumn;