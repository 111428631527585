// import { useAuthenticator } from '@aws-amplify/ui-react';
import {createSlice,createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios";
import { proxy } from "../proxy";
let initialState={
    
        candidateName:"",
        interviewerName:"",
        role:"",
        // gender:"",
        interviewDate:"",
        templateId:"",
        templateName:"",
        blocks:[{sectionName:"",
                subSectionName:"" ,
                probeAreas: [],
                roleName:"",
                expectations: [] ,
                weightage:0,
                marks:0,
                remarks:"",
                }
            ],
        isLoading:false,
}

export const sendInterviewerFormData= createAsyncThunk('/sendcandidate', async (data) => {
    
    try {
        const response = await axios.post(`${proxy}/api/candidate`, data,{
            withCredentials: true,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              'Authorization':data.jwtToken,
              "Access-Control-Allow-Credentials": true,
            },
          })
        return response.data
    } catch (err) {        
        return data;
    }
});
export const getInterviewerFormData= createAsyncThunk('/getcandidate', async (data2) => {
    

    try {
        const response = await axios.put(`${proxy}/api/candidate`, data2,{
            withCredentials: true,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              'Authorization':data2.jwtToken,
              "Access-Control-Allow-Credentials": true,
            },
          })
        // console.log(response.data)
        return response.data
    } catch (err) {        
        return data2;
    }
});



const interviewerFormSlice=createSlice({
    name:'block',
    initialState,
    reducers:{
        updateMarks:(state,action)=>{
            state.blocks[action.payload.index].marks=action.payload.marks;
        },
        updateRemarks:(state,action)=>{
            state.blocks[action.payload.index].remarks=action.payload.remarks;
        }


    },
    extraReducers(builder) {
        builder.addCase(sendInterviewerFormData.fulfilled, (state, action) => {
            state.isLoading=false;
            console.log(action.payload)
            try {
                if(action.payload.result !== null && action.payload.result !== undefined) {
                    alert("SUCCESS!! Form Submitted Successfully :-)\n\n" + JSON.stringify(action.payload.result, null, 4));
                }
            } catch (error) {
                alert("FAILURE!! Form rejected due to some internal error :(\n\n Kindly fill the form again !!" + error)
            }
            return ;
        })
        builder.addCase(sendInterviewerFormData.pending, (state, action) => {
            state.isLoading=true;
            return ;
        })
        builder.addCase(sendInterviewerFormData.rejected, (state, action) => {
            alert("Error");
            state.isLoading=false;
            return ;
        })
        builder.addCase(getInterviewerFormData.fulfilled, (state, action) => {
            // alert("templateslice");
            // console.log(action.payload)
            state.candidateName=action.payload.result.candidateName;
            state.interviewerName=action.payload.result.interviewerName;
            state.role=action.payload.result.role;
            //state.gender=action.payload.result.gender;
            state.interviewDate=action.payload.result.interviewDate;
            state.templateId=action.payload.result.templateId;
            state.templateName=action.payload.result.templateName;
            state.blocks=action.payload.result.blocks;
            state.isLoading=false;
            return ;
        })
        builder.addCase(getInterviewerFormData.pending, (state, action) => {
            // alert("templateSLice","pending")
            state.isLoading=true;
            return ;
        })
        builder.addCase(getInterviewerFormData.rejected, (state, action) => {
            alert("Error");
            state.isLoading=false;
            return ;
        })
    }  
});

export const {updateMarks,updateRemarks}=interviewerFormSlice.actions
export default interviewerFormSlice.reducer