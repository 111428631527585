
import {useDispatch} from 'react-redux';

import ClearIcon from '@mui/icons-material/Clear';
import "../styles/TemplateStaticComponents.css";
import { removeSubSection } from '../features/templateSlice';
function TemplateStaticRowSubSection(props) {
    const dispatch=useDispatch()
    return(
        <div className="template-row-subsection">
        
            <div style={{width:"90%"}}>{props.subSectionName}</div>
            <ClearIcon style={{cursor:"pointer"}} onClick={()=>{
                dispatch(removeSubSection({sectionIndex:props.sectionIndex,subSectionIndex:props.subSectionIndex}))
            }}></ClearIcon>
        </div>
           
    )

}
export default TemplateStaticRowSubSection;