import * as React from "react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { proxy } from "../../proxy";
import axios from "axios";
import { Loader, useAuthenticator } from "@aws-amplify/ui-react";
import DoughnutChart from "./DoughnutChart";
import "./Reports.css";
import {
  Divider,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import BlackButton from "../BlackButton";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import InterviewerFormView from "../InterviewerFormView";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Tooltip } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { motion } from "framer-motion";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import PinIcon from "@mui/icons-material/Pin";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import DownloadIcon from "@mui/icons-material/Download";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useSelector } from "react-redux";

function PanelistReports() {
  let date = new Date();
  date.setDate(date.getDate() - 7);

  const { user } = useAuthenticator((context) => [context.user]);
  const [isLoading, setisLoading] = useState(false);
  const [roleMismatchData, setRoleMismatchData] = useState([]);
  const [startValue, setStartValue] = React.useState(date);
  const [endValue, setEndValue] = React.useState(new Date());
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const history = useHistory();
  const [formData, setFormData] = React.useState([]);
  const [display, setDisplay] = React.useState(false);
  const [toDisplay, setToDisplay] = useState(false);
  const [order, setOrder] = React.useState("ASC");
  const profile= useSelector((state) => {
    return state.profile;
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const capitalizeFirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const caps = (str) => {
    return str.split(".").map(capitalizeFirst).join(" ");
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    getMismatchReport();
  }, [profile]);

  const getMismatchReport = async () => {
    try {
      setisLoading(true);
      const res = await axios.post(
        `${proxy}/api/rolemismatch`,
        {
          startDate: startValue,
          endDate: endValue,
          profile: profile,
        },
        {
          withCredentials: true,
          headers: {
            Authorization: user.signInUserSession.idToken.jwtToken,
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Credentials": true,
          },
        }
      );
      console.log("Candidate Mismatch\n", res.data.results);
      setisLoading(false);
      setRoleMismatchData(res.data.results);
      setDisplay(false);
      setPage(0);
    } catch (error) {
      console.log(error);
    }
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const exportRoleMismatchReport = () => {
    const ws = XLSX.utils.json_to_sheet(roleMismatchData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: "xlsx" });
    FileSaver.saveAs(data, "Role_Mismatch_Export" + ".xlsx");
  };

  const sortingNumber = (col) => {
    if (order === "ASC") {
      const sorted = [...roleMismatchData].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setRoleMismatchData(sorted);
      setOrder("DSC");
    }

    if (order === "DSC") {
      const sorted = [...roleMismatchData].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setRoleMismatchData(sorted);
      setOrder("ASC");
    }
  };

  return (
    <div style={{ width: "100%", height: "fit-content", margin: "auto" }}>
      <div>
        <div
          className="sections-header"
          style={{ height: "50px", background: "#fe414d" }}
        >
          <div className="sections-title-header">
            <p style={{ color: "white" }}>Role Mismatch Report</p>
          </div>
        </div>
        {/* <div className="chart-rows">
                    <div style={{width:"100%"}}>
                        <h1 style={{textAlign:'center'}}><AssessmentIcon style={{color:'red',fontSize:'40px'}}></AssessmentIcon>  Performance Overview Dashboard</h1>

                    </div>
                </div> */}
        {/* <Typography variant='h4' textAlign='center'>Role Mismatch Data</Typography> */}

        {/* <div style={{width:"100%"}}>
                        <h2><AdminPanelSettingsIcon style={{color:'red',fontSize:'30px'}}/> Panelist Usage Statistics</h2>
                        <Divider/>
                        </div>
                        <div className='chart-row'>                    
                                { panelistData && panelistData.map(({Disqualified,Qualified,Reiteration,interviewerName})=>{
                                        return (
                                            <div className='chart-box' style={{width:'250px'}}>
                                                <DoughnutChart chartData={{
                                                    labels: ["Qualified", "Disqualified", "Reiteration"],
                                                    datasets: [
                                                    {
                                                        label: `${interviewerName}`,
                                                        data: [Qualified, Disqualified, Reiteration],
                                                        backgroundColor: [
                                                        'rgba(255, 99, 132, 0.2)',
                                                        'rgba(54, 162, 235, 0.2)',
                                                        'rgba(255, 206, 86, 0.2)'
                                                        ],
                                                        borderColor: [
                                                        'rgba(255, 99, 132, 1)',
                                                        'rgba(54, 162, 235, 1)',
                                                        'rgba(255, 206, 86, 1)'
                                                        ],
                                                        borderWidth: 1,
                                                    },
                                                    ],
                                                }}/>
                                                <h5 style={{textAlign:"center",color:"grey",fontSize:"small"}}>{`${caps((interviewerName).replace("."," "))}`}</h5>
                                            </div>
                                        )
                                    
                                    })
                                }   

                        </div> */}
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          {!toDisplay && (
            <Tooltip title="Open Filters">
              <motion.div
                initial={{ y: -10 }}
                animate={{ y: 0 }}
                transition={{
                  duration: 1,
                  repeat: Infinity,
                  repeatType: "reverse",
                }}
              >
                <KeyboardDoubleArrowDownIcon
                  onClick={() => setToDisplay(!toDisplay)}
                  style={{
                    color: "#2192FF",
                    cursor: "pointer",
                    fontSize: "35px",
                  }}
                />
              </motion.div>
            </Tooltip>
          )}
          {toDisplay && (
            <Tooltip title="Close Filter">
              <motion.div
                initial={{ y: 0 }}
                animate={{ y: -10 }}
                transition={{
                  duration: 1,
                  repeat: Infinity,
                  repeatType: "reverse",
                }}
              >
                <KeyboardDoubleArrowUpIcon
                  onClick={() => setToDisplay(!toDisplay)}
                  style={{
                    color: "#2192FF",
                    cursor: "pointer",
                    fontSize: "35px",
                  }}
                />
              </motion.div>
            </Tooltip>
          )}
        </div>

        {toDisplay && (
          <motion.div
            key="box"
            initial={{ y: -10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{ type: "spring", duration: 2.5 }}
            className="box"
          >
            <div style={{ padding: "5px", width: "100%", marginLeft: "35%" }}>
              <Grid container spacing={1} style={{}}>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      inputFormat="DD/MM/YYYY"
                      maxDate={new Date()}
                      label="Start Date"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="startDate"
                          sx={{ width: 150, marginRight: 2 }}
                        />
                      )}
                      value={startValue}
                      onChange={(newValue) => {
                        setStartValue(new Date(newValue).toISOString());
                      }}
                    />
                    <DesktopDatePicker
                      inputFormat="DD/MM/YYYY"
                      minDate={startValue}
                      maxDate={new Date()}
                      label="End Date"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="endDate"
                          sx={{ width: 150 }}
                        />
                      )}
                      value={endValue}
                      onChange={(newValue) => {
                        setEndValue(new Date(newValue).toISOString());
                      }}
                    />
                  </LocalizationProvider>
                  <BlackButton
                    style={{ marginLeft: "6%" }}
                    onClick={getMismatchReport}
                  >
                    Apply
                  </BlackButton>
                </Grid>
              </Grid>
            </div>
          </motion.div>
        )}
        <div
          style={{
            width: "100%",
            marginLeft: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h2>
            <PinIcon style={{ color: "red", fontSize: "30px" }}></PinIcon>{" "}
            Tabular Data
          </h2>
          <Button
            className="btnFillup anglebg"
            style={{ height: "40px", marginRight: "100px" }}
            variant="outlined"
            onClick={exportRoleMismatchReport}
          >
            Export Data <DownloadIcon />
          </Button>
        </div>
        <div style={{ width: "90%", margin: "auto" }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 500 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Candidate Name</StyledTableCell>
                  <StyledTableCell>Interviewer Name</StyledTableCell>
                  <StyledTableCell>Evaluated Role</StyledTableCell>
                  <StyledTableCell>Recommended Role</StyledTableCell>
                  <StyledTableCell>
                    Domain Score&nbsp;
                    {order === "ASC" && (
                      <Tooltip title="Sort Ascending">
                        <ArrowUpwardIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => sortingNumber("totalScore")}
                        />
                      </Tooltip>
                    )}
                    {order === "DSC" && (
                      <Tooltip title="Sort Descending">
                        <ArrowDownwardIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => sortingNumber("totalScore")}
                        />
                      </Tooltip>
                    )}
                  </StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell>View Form</StyledTableCell>
                </TableRow>
              </TableHead>
              {isLoading && (
                <div
                  style={{ height: "100%", margin: "auto", marginTop: "250px" }}
                >
                  <Loader />
                </div>
              )}
              {!isLoading && (
                <TableBody>
                  {roleMismatchData &&
                    roleMismatchData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((document) => (
                        <StyledTableRow
                          hover
                          key={document._id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell component="th" scope="row">
                            {caps(document.CandidateName)}
                          </StyledTableCell>
                          <StyledTableCell>
                            {caps(document.InterviewerName)}
                          </StyledTableCell>
                          <StyledTableCell>
                            {document.EvaluatedRole}
                          </StyledTableCell>
                          <StyledTableCell style={{ color: "red" }}>
                            {document.RecommendedRole}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              color:
                                document.Status === "Qualified"
                                  ? "green"
                                  : document.Status === "Disqualified"
                                  ? "red"
                                  : "orange",
                            }}
                          >
                            {document.TotalScore}
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              color:
                                document.Status === "Qualified"
                                  ? "green"
                                  : document.Status === "Disqualified"
                                  ? "red"
                                  : "orange",
                            }}
                          >
                            {document.Status}
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            style={{ cursor: "pointer" }}
                          >
                            <Tooltip title="View File">
                              <RemoveRedEyeIcon
                                onClick={() => {
                                  setDisplay(false);
                                  axios
                                    .post(
                                      `${proxy}/api/forms`,
                                      { id: document._id },
                                      {
                                        withCredentials: true,
                                        headers: {
                                          Accept: "application/json",
                                          Authorization:
                                            user.signInUserSession.idToken
                                              .jwtToken,

                                          "Content-Type": "application/json",
                                          "Access-Control-Allow-Credentials": true,
                                        },
                                      }
                                    )
                                    .then((res) => {
                                      setFormData(res.data.results);
                                      setDisplay(true);
                                    })
                                    .catch((error) => {
                                      // console.log(error);
                                    });
                                }}
                              />
                            </Tooltip>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                </TableBody>
              )}
            </Table>
            {roleMismatchData.length === 0 && (
              <div
                style={{ textAlign: "center", fontSize: "24px", width: "100%" }}
              >
                {display ? "See the Form Below" : "No Results Found"}
              </div>
            )}
            <TablePagination
              rowsPerPageOptions={[5, 10]}
              component="div"
              count={roleMismatchData.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </div>

        {display && (
          <div style={{ padding: "20px" }}>
            <Button>
              <Tooltip title="Close">
                <CancelIcon
                  style={{ color: "red", marginLeft: "1250px" }}
                  onClick={() => {
                    setDisplay(false);
                  }}
                />
              </Tooltip>
            </Button>
          </div>
        )}
        {isLoading && <Loader />}
        {display && <InterviewerFormView data={formData} />}
      </div>
    </div>
  );
}

export default PanelistReports;
