// import { useAuthenticator } from '@aws-amplify/ui-react';
import {createSlice,createAsyncThunk } from "@reduxjs/toolkit"

import axios from "axios";
import { proxy } from "../proxy";
let initialState={
    templateName:"Add Template Name",
    templateDescription:"Add Template Description",
    template: [ ],
    isLoading:false
}
export const sendTemplateData= createAsyncThunk('/sendtemplate', async (data) => {
    // const { user} = useAuthenticator((context) => [context.user]);

    try {
        const response = await axios.put(`${proxy}/api/template`, {"data":data.data,"id":data.id},{
            withCredentials: true,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              'Authorization':data.jwtToken,
              "Access-Control-Allow-Credentials": true,
            },
          })
        return response.data
    } catch (err) {        
        return data;
    }
});
export const getTemplateData= createAsyncThunk('/gettemplate', async (data2) => {
    
    try {
        const response = await axios.post(`${proxy}/api/template_titles`, {id:data2.id},{
            withCredentials: true,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              'Authorization':data2.jwtToken,
              "Access-Control-Allow-Credentials": true,
            },
          })
        // console.log(response.data)
        return response.data
    } catch (err) {        
        return data2;
    }
});



const templateSlice=createSlice({
    name:'block',
    initialState,
    reducers:{
        addStaticComponent:(state,action)=>{
            let inputSectionId=action.payload.sectionData.sectionId;
            let inputSubSectionId=action.payload.subSectionData.subSectionId;
            // console.log(action.payload)
            let foundSection=false;
            let foundSubSection=false;
            for(let i=0;i<state.template.length;i++){
                // console.log(state.template[i].sectionId,inputSectionId)
                if(state.template[i].sectionId===inputSectionId){
                    foundSection=true;
                    for(let j=0;j<state.template[i].subSectionsData.length;j++){
                        if(state.template[i].subSectionsData[j].subSectionId===inputSubSectionId){
                                foundSubSection=true;
                        }
                    }
                    if(!foundSubSection){
                        state.template[i].subSectionsData.push({subSectionId:inputSubSectionId,subSectionName:action.payload.subSectionData.subSectionName});
                    }
                }
            }
            // console.log(foundSection,foundSubSection)
            if(!foundSection){
                state.template.push({                    
                    "sectionId":inputSectionId ,
                    "sectionName":action.payload.sectionData.sectionName,
                    "subSectionsData": [],     
                    "weightage":0})
                    state.template[state.template.length-1].subSectionsData.push({subSectionId:inputSubSectionId,subSectionName:action.payload.subSectionData.subSectionName});
                    
            }
        },
        removeSection:(state,action)=>{
            // console.log(action.payload.index)
            const index=action.payload.sectionIndex;
            if (index > -1) {
                state.template.splice(index, 1);
              }
              
        },
        removeSubSection:(state,action)=>{
            // console.log(action.payload.index)
            const sectionIndex=action.payload.sectionIndex;
            const subSectionIndex=action.payload.subSectionIndex;
            // console.log(sectionIndex,subSectionIndex)
            
            if(state.template[sectionIndex].subSectionsData.length===1){
                alert("subsections cannot be empty");
                return;
            }
            else{
                if (subSectionIndex > -1) {
                    state.template[sectionIndex].subSectionsData.splice(subSectionIndex, 1);
                  }
            }

              
        },
        updateWeightage:(state,action)=>{
            const index=action.payload.sectionIndex
            state.template[index].weightage=action.payload.weightage
            // console.log("weightage",state.template[index].weightage)
        },
        setToInitialState:(state,action)=>{

            state.templateName="Add Template Name";
            state.templateDescription="Add Template Description";
            state.template=[];
            state.isLoading=false;

        },    
        changeName:(state,action)=>{
            state.templateName=action.payload.templateName;
        },
        changeDescription:(state,action)=>{
            state.templateDescription=action.payload.templateDescription;
        },

    },
    extraReducers(builder) {
        builder.addCase(sendTemplateData.fulfilled, (state, action) => {
            state.isLoading=false;

            alert("succesfully submitted the Template");
            return ;
        })
        builder.addCase(sendTemplateData.pending, (state, action) => {
            state.isLoading=true;
            return ;
        })
        builder.addCase(sendTemplateData.rejected, (state, action) => {
            alert("Error");
            state.isLoading=false;
            return ;
        })
        builder.addCase(getTemplateData.fulfilled, (state, action) => {
            // alert("templateslice");
            state.templateName=action.payload.result.templateName;
            state.template=action.payload.result.template;
            state.isLoading=false;
            return ;
        })
        builder.addCase(getTemplateData.pending, (state, action) => {
            // alert("templateSLice","pending")
            state.isLoading=true;
            return ;
        })
        builder.addCase(getTemplateData.rejected, (state, action) => {
            alert("Error");
            state.isLoading=false;
            return ;
        })
    }  
});

export const {addStaticComponent,removeSection,removeSubSection,updateWeightage,changeName,changeDescription,setToInitialState}=templateSlice.actions
export default templateSlice.reducer