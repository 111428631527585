import * as React from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import axios from "axios";
import "../../styles/CandidateDetails.css";
import BlackButton from "../BlackButton";
import { proxy } from "../../proxy";
import { useHistory } from "react-router-dom";
import PeopleIcon from "@mui/icons-material/People";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import CreateIcon from "@mui/icons-material/Create";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Autocomplete from "@mui/material/Autocomplete";
import {
  TextField,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Box,
} from "@mui/material";
import moment from "moment";
import { useSelector } from "react-redux";

function CandidateDetailsPagePhone(props) {
  const { user } = useAuthenticator((context) => [context.user]);
  const history = useHistory();
  const [roleData, setRoleData] = useState([]);
  const [role, setRole] = useState("");
  const [recruiterData, setRecruiterData] = useState([]);
  const [template, setTemplate] = useState("");
  const [candidateName, setCandidateName] = useState("");
  // const [gender,setGender] = useState("");
  const [interviewDate, setInterviewDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [maxDate, setMaxDate] = useState(null);
  const [candidate, setCandidateData] = useState([]);
  const [emailAddress, setEmailAddress] = useState("");
  const [recruiterName, setRecruiterName] = useState("");
  const [recruiters, setRecruiters] = useState([]);
  const profile= useSelector((state) => {
    return state.profile;
  });

  useEffect(() => {
          axios
      .get(`${proxy}/api/recruiter`, {
        withCredentials: true,
        headers: {
          Authorization: user.signInUserSession.idToken.jwtToken,
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
        },
      })
      .then((res) => {
        setRecruiterData(res.data.result);
        initializeRecruiterArray(res.data.result);
        console.log(res.data.result);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    setMaxDate(moment(new Date()).format("YYYY-MM-DD"));
    window.scrollTo(0, 0);
    axios
      .get(`${proxy}/api/role`, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: user.signInUserSession.idToken.jwtToken,

          "Access-Control-Allow-Credentials": true,
        },
      })
      .then((res) => {
        console.log(res.data.result);
        setRoleData(res.data.result);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function initializeRecruiterArray(data) {
    let recruiters = [];
    if (data !== null && data !== undefined) {
      data.map(({ recruiterUserName }) => {
        recruiters.push(recruiterUserName);
        return 1;
      });
    }
    setRecruiters(recruiters);
    return true;
  }
  const handleChangeRecruiterName = (event, newValue) => {
    setRecruiterName(newValue);
  };

  const handleChangeRole = (event) => {
    setRole(event.target.value);
  };
  const handleChangeCandidateName = (event) => {
    //console.log(event.target.value)
    setCandidateName(event.target.value);
  };

  const handleChangeEmailAddress = (event) => {
    //console.log(event.target.value)
    setEmailAddress(event.target.value);
  };
  const handleChangeTemplate = (event) => {
    setTemplate(event.target.value);
  };
  // const handleChangeGender=(event)=>{
  //   setGender(event.target.value);
  // }
  const handleChangeInterviewDate = (event) => {
    console.log(event.target.value);
    setInterviewDate(event.target.value);
  };

  const validationSchema = Yup.object().shape({
    candidateName: Yup.string().required("Candidate Name is required"),
    role: Yup.string().required("Role is required"),
    template: Yup.string().required("Template Name is required"),
    emailAddress: Yup.string()
      .email("Email Address must be valid")
      .matches(/^(?!.*@[^,]*,)/)
      .required("Email Address is required"),
    recruiterName: Yup.string().required("Recruiter Name is required"),
    // gender: Yup.string()
    //     .required('Gender is required')
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  function onSubmit(data) {
    // display form data on success
    // console.log(formState);
    console.log("SUCCESS!! :-)\n\n" + JSON.stringify(data, null, 4));
    sendReportsData({
      candidateName: data.candidateName,
      roleId: data.role,
      templateName: data.template,
      // gender:data.gender,
      interviewDate: interviewDate,
      emailAddress: data.emailAddress,
      interviewer: user.username,
      interviewerStatus: true,
      profile: user.attributes.profile,
      interviewType: "Phone",
      recruiterName: recruiterName
    });
    return false;
  }

  //   const sendReportsData = async(data) => {
  //     try {
  //         const  res = await axios.post(`${proxy}/api/candidatedetails`,data, {
  //             withCredentials: true,
  //             headers: {
  //                 'Authorization':user.signInUserSession.idToken.jwtToken,
  //                 Accept: "application/json",
  //                 "Content-Type": "application/json",
  //                 "Access-Control-Allow-Credentials": true,
  //             },
  //         }).then(()=> {
  //           setCandidateData(res.data.result);
  //           console.log(res.data.result);
  //         }).then(()=> {
  //           console.log('Incoming data==>' + JSON.stringify(candidate,null,4))
  //           history.push(`/interviewform/${candidate._id}`);
  //         });

  //     } catch (error) {
  //         console.log(error);
  //     }
  // }

  const sendReportsData = (data) => {
    axios
      .post(`${proxy}/api/candidatedetails`, data, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          Authorization: user.signInUserSession.idToken.jwtToken,
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      })
      .then((res) => {
        setCandidateData(res.data.result);
        console.log(res.data.result);
        // console.log("Incoming Data ===>" + res.data.result._id)
        history.push(`/interviewformphone/${res.data.result._id}`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="candidate-form">
      <p className="candidate-form-header">Enter Candidate Details </p>
      <div className="box">
        <div className="box-secondary">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ width: "100%" }}>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={6}>
                  <div className="candidate-form-option-title">
                    <p>
                      <PeopleIcon
                        style={{ color: "red", marginRight: "5px" }}
                      ></PeopleIcon>
                      Candidate Name
                    </p>
                  </div>
                  <TextField
                    label="Candidate Name"
                    value={formState.candidateName}
                    onChange={handleChangeCandidateName}
                    placeholder="Enter Candidate Name"
                    variant="outlined"
                    fullWidth
                    name="candidateName"
                    {...register("candidateName")}
                    error={Boolean(errors.candidateName)}
                    helperText={errors.candidateName?.message}
                  />
                </Grid>
                <Grid item xs={6}>
                  <div className="candidate-form-option-title">
                    <p>
                      <FileCopyIcon
                        style={{ color: "red", marginRight: "5px" }}
                      ></FileCopyIcon>
                      Candidate Email Id
                    </p>
                  </div>
                  <TextField
                    label="Candidate Email Id"
                    value={formState.candidateName}
                    onChange={handleChangeEmailAddress}
                    placeholder="Enter Candidate Email Address"
                    variant="outlined"
                    fullWidth
                    name="emailAddress"
                    {...register("emailAddress")}
                    error={Boolean(errors.emailAddress)}
                    helperText={errors.emailAddress?.message}
                  />
                </Grid>
                <Grid item xs={6}>
                  <div className="candidate-form-option-title">
                    <p>
                      <CalendarTodayIcon
                        style={{ color: "red", marginRight: "5px" }}
                      ></CalendarTodayIcon>
                      Interview Scheduled Date
                    </p>
                  </div>
                  <FormControl fullWidth>
                    <input
                      name="Interview Date"
                      type="date"
                      max={maxDate}
                      value={interviewDate}
                      onChange={handleChangeInterviewDate}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <div className="candidate-form-option-title">
                    <p>
                      <PeopleIcon
                        style={{ color: "red", marginRight: "5px" }}
                      ></PeopleIcon>
                      Hiring POC
                    </p>
                  </div>
                  <FormControl fullWidth error={Boolean(errors.recruiterName)}>
                    {/* <Select
                      name="recruiterName"
                      label="Hiring POC"
                      value={formState.recruiterName}
                      onChange={handleChangeRecruiterName}
                      {...register("recruiterName")}
                    >
                      {recruiterData.map(
                        ({ _id, recruiterName, recruiterUserName }, index) => {
                          return (
                            <MenuItem key={_id} value={recruiterUserName}>
                              {recruiterName}
                            </MenuItem>
                          );
                        }
                      )}
                    </Select> */}
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={recruiters}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Hiring POC"
                          name="recruiterName"
                          {...register("recruiterName")}
                        />
                      )}
                      onChange={handleChangeRecruiterName}
                    />

                    <FormHelperText>
                      {errors.recruiterName?.message}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <div className="candidate-form-option-title">
                    <p>
                      <FileCopyIcon
                        style={{ color: "red", marginRight: "5px" }}
                      ></FileCopyIcon>
                      Template
                    </p>
                  </div>
                  <FormControl fullWidth error={Boolean(errors.template)}>
                    <InputLabel id="demo-simple-select-label">
                      Choose Template
                    </InputLabel>
                    <Select
                      name="template"
                      label="Choose Template"
                      value={formState.templateId}
                      onChange={handleChangeTemplate}
                      {...register("template")}
                    >
                        <MenuItem value="AWS">
                        AWS
                        </MenuItem>
                        <MenuItem value="GCP">
                        GCP
                        </MenuItem>
                        <MenuItem value="Azure">
                        Azure
                        </MenuItem>
                    </Select>
                    <FormHelperText>{errors.template?.message}</FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <div className="candidate-form-option-title">
                    <p>
                      <CreateIcon
                        style={{ color: "red", marginRight: "5px" }}
                      ></CreateIcon>
                      Role
                    </p>
                  </div>
                  <FormControl fullWidth error={Boolean(errors.role)}>
                    <InputLabel id="demo-simple-select-label">
                      Choose Role
                    </InputLabel>
                    <Select
                      label="Choose Role"
                      name="role"
                      value={formState.role}
                      onChange={handleChangeRole}
                      {...register("role")}
                    >
                      {roleData.map(({ _id, role }, index) => {
                        return (
                          <MenuItem key={index} value={_id}>
                            {role}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText>{errors.role?.message}</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <BlackButton fullWidth type="submit">
                  Submit
                </BlackButton>
              </Grid>
            </Box>
          </form>
        </div>
      </div>
    </div>
  );
}
export default CandidateDetailsPagePhone;
