import * as React from "react";

import "../styles/Block.css";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch } from "react-redux";
import { removeProbeArea } from "../features/blockSlice";
import {
  addRecommendedProbeArea,
  removeRecommendedProbeArea,
} from "../features/blockSlice";
import { useState } from "react";

function BlockProbeArea(props) {
  const dispatch = useDispatch();
  const handleCheck = (event) => {
    if (event.target.checked) {
      // updatedList = [...checked, event.target.value];
      dispatch(addRecommendedProbeArea({ recommendedProbeArea: props.point }));
    } else {
      // updatedList.splice(checked.indexOf(event.target.value), 1);
      dispatch(removeRecommendedProbeArea({ index: props.index }));
    }
  };
  return (
    <div className="block-probearea-tile">
      <div className="block-probearea-index">{props.index + 1}. &nbsp;</div>
      <div className="block-probearea-text"> {props.point}</div>
      <input value={props.point} type="checkbox" onChange={handleCheck} />
      <ClearIcon
        className="block-probearea-clear"
        style={{ width: "20px" }}
        onClick={() => {
          dispatch(removeProbeArea({ index: props.index }));
        }}
      />
    </div>
  );
}

export default BlockProbeArea;
