import "../styles/BlocksDisplayer.css"
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getFormData } from "../features/blockSlice";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  borderRadius:'0px',
  cursor:"pointer",
  color: theme.palette.text.secondary,
}));

function BlockDisplay(props) {
    let history = useHistory();

    const dispatch=useDispatch()
    const [isOpen,setisOpen]=useState(false);
    return(
        <div className="block-display-box">

            <div className="block-display-header" >
                <p> Building Block</p>
                <div className="block-display-options">
                    <MoreVertIcon style={{boxSizing:"border-box" ,padding:"5px",width:"50px",height:"40px",marginLeft:"auto",boxShadow:"box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;",cursor:"pointer"}} onClick={()=>{
                        setisOpen(!isOpen)
                    }}/>
                    {isOpen && <Box className="block-display-options-Box">
                        <Stack spacing={0}>
                            <Link to={`/blocks/${props.blockId}`} style={{textDecoration:"none"}}>
                                <Item className="block-display-item">View</Item>
                            </Link>
                            <Item className="block-display-item" onClick={()=>{
                                dispatch(getFormData({"id":props.blockId}))
                                history.push("/block")

                            }}>Edit</Item>
                            <Item className="block-display-item">Delete</Item>
                        </Stack>
                    </Box>}
                </div>

            </div>
            <div className="block-display-content">
                <div className="block-display-col">
                    <p className="block-display-title">Section<br/></p>
                    <p style={{fontWeight:"bolder"}}>{props.section}<br/></p>
                </div>
                <div className="block-display-col">
                    <p className="block-display-title">Sub Section<br/></p>
                    <p style={{fontWeight:"bolder"}}>{props.subSection}<br/></p>
                </div>

            </div>

    </div>
    )
}
export default BlockDisplay;