import { useAuthenticator } from '@aws-amplify/ui-react';
import React, { useEffect, useState } from 'react';
import '../styles/CreateForm.css';
import InterviewerFormRow from './InterviewerFormRow';
import { useSelector } from "react-redux";
import Loader from "./Loader"
import InterviewerFormSubmit from './InterviewerFormSubmit';
import { InterviewerFormCandidateTable } from './InterviewerFormTables';
import { InterviewerProficiencyTable } from './InterviewerFormTables';
import Modal from 'react-modal';
import { Button } from '@mui/material';
import axios from 'axios';
import { proxy } from '../proxy';
function InterviewerForm() {
  const { user} = useAuthenticator((context) => [context.user]);
  const [roleData,setRoleData]=useState([])
  useEffect(() => {
    window.scrollTo(0, 0)
    axios.get(`${proxy}/api/role`, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          'Authorization':user.signInUserSession.idToken.jwtToken,
          
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      }).then((res) => {
          
          setRoleData(res.data.result);
      }).catch((error)=>{
        console.log(error)
      });


  }, []);
  const [modalIsOpenCandidate, setIsOpenCandidate] = React.useState(false);
  const [modalIsOpenMarks, setIsOpenMarks] = React.useState(false);
  function openModalCandidate() {
    setIsOpenCandidate(true);
  }
  function closeModalCandidate() {
    setIsOpenCandidate(false);
  }
  function openModalMarks() {
    setIsOpenMarks(true);
  }
  function closeModalMarks() {
    setIsOpenMarks(false);
  }

  const blockData=useSelector((state)=>{
      return state.interviewerForm.blocks
  })

  const isLoading=useSelector((state)=>{
    return state.interviewerForm.isLoading
  })
  const templateName=useSelector((state)=>{
    return state.interviewerForm.templateName
  })
  const rows=blockData.map((blockData,index) => {
    //   console.log("rowdata",rowdata)
            return(
                <InterviewerFormRow key={index} index={index} data={blockData}/>
            )
    })

  return(
    <div style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",gap:"1px"}}>
      <div style={{textAlign:"center",width:"100%",display:"flex",justifyContent:"flex-end"}}>
        <div style={{width:"50%",textAlign:"right"}}>
          <h3 style={{color:"#fe414d"}}>{templateName==="" ? "Not Selected":templateName}</h3>
        </div>
        
        <div style={{width:"50%",display:"flex",alignItems:"center",justifyContent:"center",gap:"10px"}}>
          <Button variant="outlined" onClick={openModalCandidate}>Candidate Details</Button>
          <Button variant="outlined" onClick={openModalMarks}>Marks Details</Button>
        </div>
      </div>
      <div>
      <Modal
        isOpen={modalIsOpenCandidate}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModalCandidate}
        styles={{width:"40px",height:"fit-content",padding:"20px"}}
        contentLabel="Example Modal"
        className="Modal"
        >
        <InterviewerFormCandidateTable/>
      </Modal>
      <Modal
        isOpen={modalIsOpenMarks}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModalMarks}
        contentLabel="Example Modal"
        className="Modal"
        >
        <InterviewerProficiencyTable/>
      </Modal>
      </div>
      {isLoading && <Loader/>}
      <div className='form-wrapper' style={{maxWidth:"100%",boxShadow:"0px 0px 0px 0px",gap:"0px"}} >
          {!isLoading && 
          <div className='createform' style={{margin:"auto"}}>
            <div style={{display:"flex",flexDirection:"Column",gap:"0px"}}>
              {rows}
            </div>
          </div>
          }
      </div>
      <InterviewerFormSubmit roleData={roleData}/>
    </div>
  )
  }
  export default InterviewerForm;