import { useAuthenticator } from '@aws-amplify/ui-react';
import React, { useState } from "react";
import Role from "./Role";
// import TextField from "@mui/material/TextField";
// import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import AddIcon from '@mui/icons-material/Add';
import axios from "axios"
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import Fab from "@mui/material/Fab";
import Loader from "./Loader";
import { proxy } from "../proxy";
import { useSelector } from 'react-redux';
function RolesDisplayer(){
  const { user} = useAuthenticator((context) => [context.user]);
  const [isLoading,setisLoading]=useState(true);   
  const [data,setData]=useState([]);
  
  useEffect(() => {
    window.scrollTo(0,0)
    axios.get(`${proxy}/api/role`, {
      withCredentials: true,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization':user.signInUserSession.idToken.jwtToken,
        "Access-Control-Allow-Credentials": true,
      },
    }).then((res) => {
      setisLoading(false);
      // console.log(res.data.result)
      // console.log(profile);
        setData(res.data.result)
    }).catch((error)=>{
      setisLoading(false);
      console.log(error)
    });
        
// eslint-disable-next-line
    }, []);
  const roleTiles=data.map(({_id,role},index)=>{
      // console.log(section,subSections,_id)
      return (<Role key={index} mongoid ={_id} role={role}/>)
  })

  return(
    <div>
      {isLoading && <Loader/>}
      {!isLoading && 
      <div className="sections-div" >
          <div className="sections-header" style={{background:"rgb(254, 65, 77)"}}>
            <div className="sections-title-header" >
                <p style={{color:"#fff"}}>  Roles  </p>
            </div>
          </div>

          <div className="sections-tiles-displayer" style={{background:"#fff",width:"90%",marginTop:"30px",boxShadow:"rgba(0, 0, 0, 0.24) 0px 3px 8px"}}>
            {roleTiles}
          </div>
          <Link to="/addrole" style={{textDecoration:"none",color:"white",backgroundColor:"#aeea00"}}>
            <Tooltip title="Add Role">
                <Fab backgroundColor="#aeea00" aria-label="add" style={  {position: 'fixed',bottom:16,right:16,backgroundColor:"#000"}}>
                        <AddIcon style={{color:"white"}}/>    
                </Fab>
            </Tooltip>
          </Link>
      </div>}
    </div>

  )
    
}

export default RolesDisplayer;