import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import {Link} from "react-router-dom"
import EngineeringIcon from '@mui/icons-material/Engineering';
import IconButton from '@mui/material/IconButton';
import {proxy} from "../proxy.js";
import axios from "axios";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { motion } from 'framer-motion';
function Role(props){
    const { user } = useAuthenticator((context) => [context.user]);
      const handleRoleDeletion = () => {
        axios.delete(`${proxy}/api/role/${props.mongoid}`,{
          withCredentials: true,
          headers: {
            Accept: "application/json",
            'Authorization':user.signInUserSession.idToken.jwtToken,
            "Content-Type": "application/json",
            "Access-Control-Allow-Credentials": true,
          },
        }).then((res) => {
        //  setisLoading(false);
          console.log(res.data.result);
          window.location.reload();
        }).catch((error)=>{
        //  setisLoading(false);
        console.log(error);
      });
    };
    return(
      <motion.div whileHover={{scale:1.02}}>
        <div className="subsection-div" >
            <div className="subsection-title" style={{display:"flex",alignItems:"center",gap:"20px",fontSize:"large"}}>
                <EngineeringIcon style={{color:"rgb(254, 65, 77)"}}/>

                {props.role}
            </div>
            <div className="subsection-icons">
                {/* <Link  to={`/${props.role}/${props.mongoid}`} style={{textDecoration:"none",color:"#000"}}>
                    <EditIcon/>
                </Link> */}
                <IconButton style={{textDecoration:"none",color:"#000"}} aria-label="Delete a Role" onClick={handleRoleDeletion}>
                    <ClearIcon/>
                </IconButton>
                       
            </div>

        </div>
        </motion.div>
    )
}

export default Role;