import React from 'react';
import '../styles/AddRowCol.css';
import { addRow,addColumn } from '../features/adminFormSlice';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import { useSelector } from "react-redux"
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ColumnTitleDisplayer from './ColTitleDisplayer';

function AddRowCol(props){
    const [role, setrole] = React.useState('');
    const columns=useSelector((store)=>{
        // console.log(store.adminform.rows)
        if(store.adminform.rows.length>0){
            return (store.adminform.rows[0].positions.map((position,index) => {
                return(
                    <ColumnTitleDisplayer key={index} index={index} role={position.name}/>
                )
            }))
        }
        else{
            return( <div></div>)
        }

    })
    const handleChange = (event) => {
        setrole(event.target.value);
      console.log(event.target.value)
    };
    const dispatch=useDispatch();
    return(
        <div className='add-row-col-box'>
            <Button variant="contained" onClick={()=>{
                dispatch(addRow());
            }}>Add Row</Button>
            <Box sx={{ minWidth: 250 }}>
                <FormControl fullWidth>
                    <InputLabel id="">Select Role</InputLabel>
                    <Select
                        labelId=""
                        id=""
                        value={role}
                        label="Select Role"
                        onChange={handleChange}
                    >
                        <MenuItem value={"Associate"}>Associate</MenuItem>
                        <MenuItem value={"Sr.Associate"}>Sr.Associate</MenuItem>
                        <MenuItem value={"Jr.Associate"}>Jr.Associate</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Button variant="contained" onClick={()=>{
                dispatch(addColumn({"role":role}));
            }}>Add Role</Button>
            {columns}
        </div>
    )
}
export default AddRowCol;