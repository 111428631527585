import { configureStore } from "@reduxjs/toolkit";
import blockSlice from "../features/blockSlice";
import adminformSlice from "../features/adminFormSlice";
import templateSlice from "../features/templateSlice";
import interviewerFormSlice from "../features/interviewerFormSlice";
import profileSlice from "../features/profileSlice";

export const store=configureStore({
    reducer:{
        adminform:adminformSlice,
        block:blockSlice,
        template:templateSlice,
        interviewerForm:interviewerFormSlice,
        profile:profileSlice,
    }
})