import * as React from "react";
import { Loader, useAuthenticator } from "@aws-amplify/ui-react";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { proxy } from "../proxy";
import axios from "axios";
import { useEffect } from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import "../styles/CandidateProfile.css";
import "./charts/Reports.css";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import WorkIcon from "@mui/icons-material/Work";
import LaunchIcon from "@mui/icons-material/Launch";
import PeopleIcon from "@mui/icons-material/People";
import { decode as base64_decode } from "base-64";
import { motion } from "framer-motion";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function CandidateProfile() {
  const { candidateid } = useParams();
  const { user } = useAuthenticator((context) => [context.user]);
  const [candidate, setCandidateData] = useState([]);
  const [chooseBoxLink, setBoxLink] = useState("");
  const [gender, setGender] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [marksSection, setMarksSection] = useState(false);
  const [candidatedetails, setCandidateDetails] = useState(null);
  const [docketApprovalRemarks, setDocketApprovalRemarks] = useState("");
  const [docketApproval, setDocketApproval] = useState("");
  const [open, setOpen] = React.useState(false);
  let jobCount = 1;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const boxLinkHandler = (event) => {
    setBoxLink(event.target.value);
  };

  const handleChangeGender = (event) => {
    setGender(event.target.value);
  };

  const handleChangeDocketApprovalRemarks = (event) => {
    setDocketApprovalRemarks(event.target.value);
  };

  const handleDocketApproval = (event) => {
    setDocketApproval(event.target.value);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const capitalizeFirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const caps = (str) => {
    return str.split(".").map(capitalizeFirst).join(" ");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    handleReport();
  }, []);

  const handleMarksSection = (id) => {
    window.scrollTo(0, 0);
    setMarksSection(true);
    let candidateArray = [];
    candidateArray.push(candidate[id]);
    setCandidateDetails(candidateArray);
  };

  async function handleReport() {
    setisLoading(true);
    try {
      const res = await axios.get(
        `${proxy}/api/candidateprofile/${base64_decode(candidateid)}`,
        {
          withCredentials: true,
          headers: {
            Authorization: user.signInUserSession.idToken.jwtToken,
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Credentials": true,
          },
        }
      );

      console.log("Data :--->", res.data.result);
      setisLoading(false);
      setCandidateData(res.data.result);
      // setDocketApprovalRemarks(res.data.result.approvalRemarks);
      // setDocketApproval(res.data.result.docketApproval);
      if (res.data.result.length > 0) {
        window.scrollTo(0, 0);
        setMarksSection(true);
        let candidateArray = [];
        candidateArray.push(res.data.result[0]);
        setCandidateDetails(candidateArray);
        // console.log(candidatedetails);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function sendCandidateProfileData(formId) {
    try {
      const response = await axios.put(
        `${proxy}/api/candidateprofile`,
        {
          formId: formId,
          gender: gender,
          boxLink: chooseBoxLink,
          candidateId: base64_decode(candidateid),
          docketApproval: docketApproval,
          approvalRemarks: docketApprovalRemarks,
        },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: user.signInUserSession.idToken.jwtToken,
            "Access-Control-Allow-Credentials": true,
          },
        }
      );
      setOpen(true);
      window.location.reload();
      // setBoxLink("");
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <React.Fragment>
      <div
        className="sections-header"
        style={{ height: "50px", background: "#fe414d" }}
      >
        <div className="sections-title-header">
          <p style={{ color: "white" }}>Candidate Profile</p>
        </div>
      </div>
      <div className="outer-div">
        {/* <div className="sections-title-header">
        <p style={{ color: "white" }}>Full Panel Report</p>
      </div> */}
        <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Docket Data Updated at {new Date().toLocaleTimeString()} !!
          </Alert>
        </Snackbar>
        {isLoading && (
          <div style={{ height: "100%", margin: "auto" }}>
            <Loader />
          </div>
        )}
        {!isLoading && (
          <div className="section">
            <div className="flex_items1">
              <div className="candidate_name_div profile">
                {candidate
                  .slice(0, 1)
                  .map(({ candidateName, emailAddress, gender }) => {
                    return (
                      <div className="candidate_initial">
                        <Typography className="flex-content">
                          <span>Name: </span>
                          {candidateName}
                        </Typography>
                        {emailAddress && (
                          <Typography className="flex-content">
                            <span>Email: </span>
                            {emailAddress}
                          </Typography>
                        )}
                        {!emailAddress && (
                          <Typography className="flex-content">
                            <span>Email: </span>Email Not Set
                          </Typography>
                        )}
                        {gender && (
                          <Typography className="flex-content">
                            <span>Gender: </span>
                            {gender}
                          </Typography>
                        )}
                        {!gender && (
                          <React.Fragment>
                            <Typography className="detail-heading">
                              Gender:{" "}
                            </Typography>
                            <FormControl
                              variant="standard"
                              required
                              sx={{ m: 1, minWidth: 120 }}
                            >
                              <InputLabel id="demo-simple-select-standard-label">
                                Select Gender
                              </InputLabel>
                              <Select
                                autoFocus
                                labelId="demo-simple-select-required-label"
                                id="demo-simple-select-required"
                                name="marks"
                                value={gender}
                                onChange={handleChangeGender}
                                style={{ height: "40px" }}
                                label="Select Approval"
                              >
                                <MenuItem key={0} value="Man">
                                  Man
                                </MenuItem>
                                <MenuItem key={1} value="Woman">
                                  Woman
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </React.Fragment>
                        )}
                      </div>
                    );
                  })}
              </div>
              <div className="candidate_name_div profile">
                <div className="profile_grid profile_information">
                  {candidate.map((item, index) => {
                    return (
                      <Timeline className="timeline" key={item.formId}>
                        <TimelineItem className="timeline_firstItem">
                          <TimelineSeparator>
                            <TimelineDot className="timeline_dot_header">
                              <WorkIcon />
                            </TimelineDot>
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>
                            <Typography
                              variant="h6"
                              className="timeline_header"
                            >
                              Interview Details {jobCount++}
                            </Typography>
                          </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                          <CustomTimeLineSeparator />
                          <TimelineContent className="timeline-item">
                            {/* <Typography className='timelineItem_text'><span>Role : </span>{roleApplied}</Typography> */}
                            <motion.div whileHover={{ scale: 1.05 }}>
                              <div
                                className="timeline-item_content"
                                onClick={() => handleMarksSection(index)}
                              >
                                <Link>
                                  <div className="timeline-item_info">
                                    <div className="timeline-item_info-heading">
                                      <span>Role: </span>
                                      <h3 style={{ marginLeft: "40px" }}>
                                        {item.roleApplied}
                                      </h3>
                                    </div>
                                    <div className="timeline-item_info-heading">
                                      <span>Status: </span>
                                      <h3
                                        style={{
                                          marginLeft: "28px",
                                          color:
                                            item.status === "Qualified"
                                              ? "green"
                                              : item.status === "Reiteration"
                                              ? "orange"
                                              : "red",
                                        }}
                                      >
                                        {item.status}
                                      </h3>
                                    </div>
                                    <div className="timeline-item_info-heading">
                                      <span>Score: </span>
                                      <h3
                                        style={{
                                          marginLeft: "32px",
                                          color:
                                            item.status === "Qualified"
                                              ? "green"
                                              : item.status === "Reiteration"
                                              ? "orange"
                                              : "red",
                                        }}
                                      >
                                        {item.totalScore}
                                      </h3>
                                    </div>
                                    <div className="timeline-item_info-heading">
                                      <span>Date: </span>
                                      <h3 style={{ marginLeft: "40px" }}>
                                        {new Date(
                                          item.interviewDate
                                        ).toLocaleDateString("en-us", {
                                          weekday: "long",
                                          year: "numeric",
                                          month: "short",
                                          day: "numeric",
                                        })}
                                      </h3>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            </motion.div>
                          </TimelineContent>
                        </TimelineItem>
                        <TimelineSeparator className="separator_padding">
                          <TimelineDot
                            variant="outlined"
                            className="timeline_dot"
                          />
                        </TimelineSeparator>
                      </Timeline>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="flex_items2 profile container_shadow">
              {marksSection && (
                <div className="profile_right section_content">
                  <Grid item className="section_title">
                    <h6 className="section_title_text">
                      Interview High Level Details
                    </h6>
                  </Grid>
                  <Grid item xs={12}>
                    {candidatedetails &&
                      candidatedetails.length > 0 &&
                      candidatedetails.map(
                        ({
                          formId,
                          interviewerName,
                          roleApplied,
                          status,
                          templateEvaluated,
                          totalScore,
                          preferredRole,
                          marksObtained,
                          interviewDate,
                          boxLink,
                        }) => {
                          return (
                            <React.Fragment>
                              <div className="detail-container">
                                <div className="detail-container-box">
                                  <Typography className="detail-heading">
                                    Result:{" "}
                                  </Typography>
                                  <Typography className="detail-content">
                                    <span
                                      style={{
                                        color:
                                          status === "Qualified"
                                            ? "green"
                                            : status === "Reiteration"
                                            ? "orange"
                                            : "red",
                                      }}
                                    >
                                      {status}
                                    </span>
                                  </Typography>
                                </div>
                                <div className="detail-container-box">
                                  <Typography className="detail-heading">
                                    Total Score:{" "}
                                  </Typography>
                                  <Typography className="detail-content">
                                    <span
                                      style={{
                                        color:
                                          status === "Qualified"
                                            ? "green"
                                            : status === "Reiteration"
                                            ? "orange"
                                            : "red",
                                      }}
                                    >
                                      {totalScore}
                                    </span>
                                  </Typography>
                                </div>
                                <div className="detail-container-box">
                                  <Typography className="detail-heading">
                                    Template Used:{" "}
                                  </Typography>
                                  <Typography className="detail-content">
                                    {templateEvaluated}
                                  </Typography>
                                </div>
                              </div>
                              <div className="detail-container">
                                <div className="detail-container-box">
                                  <Typography className="detail-heading">
                                    Interviewer Name:{" "}
                                  </Typography>
                                  <Typography className="detail-content">
                                    {caps(interviewerName)}
                                  </Typography>
                                </div>
                                <div className="detail-container-box">
                                  <Typography className="detail-heading">
                                    Evaluated Role:{" "}
                                  </Typography>
                                  <Typography className="detail-content">
                                    {roleApplied}
                                  </Typography>
                                </div>
                                <div className="detail-container-box">
                                  <Typography className="detail-heading">
                                    Recommended Role:{" "}
                                  </Typography>
                                  <Typography className="detail-content">
                                    {preferredRole}
                                  </Typography>
                                </div>
                              </div>
                              <div className="detail-container">
                                {/* <div className='detail-container-box'>
                                                <Typography className='detail-heading'>Date of Interview: </Typography>
                                                <Typography className='detail-content'>{new Date(interviewDate).toLocaleDateString('en-us',{weekday:"long", year:"numeric", month:"short", day:"numeric"})}</Typography>
                                            </div> */}
                                <div className="detail-container-box">
                                  <Typography
                                    className="detail-box-link"
                                    style={{ marginBottom: "5px" }}
                                  >
                                    Enter Lion Box Link:{" "}
                                  </Typography>
                                  <TextField
                                    fullWidth
                                    label="Lion Box Link"
                                    value={chooseBoxLink}
                                    onChange={boxLinkHandler}
                                    placeholder="Enter Box Link"
                                    variant="standard"
                                    type="search"
                                    name="boxLink"
                                  />
                                </div>
                                <div className="detail-container-box">
                                  {boxLink && (
                                    <Link
                                      href={boxLink}
                                      target="_blank"
                                      underline="hover"
                                    >
                                      <div className="btn_icon_container_box-link">
                                        <Tooltip title="Open Box Link">
                                          <LaunchIcon />
                                        </Tooltip>
                                      </div>
                                    </Link>
                                  )}
                                  {!boxLink && (
                                    <Tooltip title="Link not saved">
                                      <div className="btn_icon_container_box-link">
                                        <span style={{ color: "white" }}>
                                          N/A
                                        </span>
                                      </div>
                                    </Tooltip>
                                  )}
                                  {/* {chooseBoxLink == "" ||
                                  (boxLink && (
                                    <Link
                                      href={
                                        chooseBoxLink !== ""
                                          ? chooseBoxLink
                                          : boxLink
                                      }
                                      target="_blank"
                                      underline="hover"
                                    >
                                      <div className="btn_icon_container_box-link">
                                        <Tooltip title="Open Box Link">
                                          <LaunchIcon />
                                        </Tooltip>
                                      </div>
                                    </Link>
                                  ))} */}
                                </div>
                                <div className="detail-container-box">
                                  <Typography className="detail-heading">
                                    Mark Approval:{" "}
                                  </Typography>
                                  <FormControl
                                    variant="standard"
                                    required
                                    sx={{ m: 1, minWidth: 120 }}
                                  >
                                    <InputLabel id="demo-simple-select-standard-label">
                                      Select Approval
                                    </InputLabel>
                                    <Select
                                      autoFocus
                                      labelId="demo-simple-select-required-label"
                                      id="demo-simple-select-required"
                                      name="marks"
                                      value={docketApproval}
                                      onChange={handleDocketApproval}
                                      style={{ height: "40px" }}
                                      label="Select Approval"
                                    >
                                      <MenuItem
                                        key={0}
                                        value="Approved"
                                        style={{ color: "green" }}
                                      >
                                        Approved
                                      </MenuItem>
                                      <MenuItem
                                        key={1}
                                        value="DisApproved"
                                        style={{ color: "red" }}
                                      >
                                        DisApproved
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </div>
                              </div>
                              <div className="detail-container">
                                {/* <div className="detail-container-box">
                                <Typography className="detail-heading">
                                  Mark Approval:{" "}
                                </Typography>
                                <FormControl
                                  variant="standard"
                                  required
                                  sx={{ m: 1, minWidth: 120 }}
                                >
                                  <InputLabel id="demo-simple-select-standard-label">
                                    Select Approval
                                  </InputLabel>
                                  <Select
                                    autoFocus
                                    labelId="demo-simple-select-required-label"
                                    id="demo-simple-select-required"
                                    name="marks"
                                    value={docketApproval}
                                    onChange={handleDocketApproval}
                                    style={{ height: "40px" }}
                                    label="Select Approval"
                                  >
                                    <MenuItem
                                      key={0}
                                      value="Approved"
                                      style={{ color: "green" }}
                                    >
                                      Approved
                                    </MenuItem>
                                    <MenuItem
                                      key={1}
                                      value="DisApproved"
                                      style={{ color: "red" }}
                                    >
                                      DisApproved
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </div> */}
                                <div className="detail-container-box">
                                  <Typography className="detail-heading">
                                    Write Approval Remarks:
                                  </Typography>
                                  <TextField
                                    style={{ width: "200%" }}
                                    id="outlined-multiline-static"
                                    variant="standard"
                                    autoFocus
                                    multiline
                                    rows={2}
                                    value={docketApprovalRemarks}
                                    onChange={handleChangeDocketApprovalRemarks}
                                    name="remarks"
                                  />
                                </div>
                              </div>
                              <div className="detail-container">
                                <div className="detail-container-box"></div>
                                <div className="detail-container-box"></div>
                                <div className="detail-container-box">
                                  <div className="detail-container-update-button">
                                    <div className="details-container-button-box">
                                      <div className="button_container">
                                        <Button
                                          onClick={() =>
                                            sendCandidateProfileData(formId)
                                          }
                                          className="custom_btn"
                                          endIcon={
                                            <div className="btn_icon_container">
                                              <SendIcon />
                                            </div>
                                          }
                                        >
                                          <span className="btn_text">
                                            Update
                                          </span>
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <Grid item>
                                <h6 className="section_title_text">
                                  Section Wise Marks Dropdown
                                </h6>
                              </Grid>
                              <div style={{ margin: "auto", width: "75%" }}>
                                <Tooltip
                                  title={
                                    expanded == false
                                      ? "Click to expand"
                                      : "Click to collapse"
                                  }
                                >
                                  <Accordion
                                    expanded={expanded === "panel1"}
                                    onChange={handleChange("panel1")}
                                  >
                                    <AccordionSummary
                                      expandIcon={
                                        expanded == false ? (
                                          <ExpandMoreIcon />
                                        ) : (
                                          <ExpandLessIcon />
                                        )
                                      }
                                      aria-controls="panel4bh-content"
                                      id="panel4bh-header"
                                    >
                                      <Typography sx={{ fontWeight: "bold" }}>
                                        Marks Dropdown
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <TableContainer component={Paper}>
                                        <Table
                                          sx={{ minWidth: "650" }}
                                          aria-label="Simple Table"
                                        >
                                          <TableHead
                                            style={{
                                              backgroundColor: "#FA5F55",
                                            }}
                                          >
                                            <TableRow>
                                              <TableCell
                                                style={{ color: "white" }}
                                              >
                                                <b>Section Name</b>
                                              </TableCell>
                                              <TableCell
                                                align="right"
                                                style={{ color: "white" }}
                                              >
                                                <b>Ratings</b>
                                              </TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {marksObtained &&
                                              marksObtained.map(
                                                (marks, index) => {
                                                  return (
                                                    <TableRow
                                                      sx={{
                                                        "&:last-child td, &:last-child th":
                                                          { border: 0 },
                                                      }}
                                                    >
                                                      <TableCell
                                                        component="th"
                                                        scope="row"
                                                      >
                                                        {marks[0]}
                                                      </TableCell>
                                                      <TableCell align="right">
                                                        {marks[1] === 0
                                                          ? "No Experience"
                                                          : marks[1] === 1
                                                          ? "Beginner"
                                                          : marks[1] === 2
                                                          ? "Intermediate"
                                                          : marks[1] === 3
                                                          ? "Proficient"
                                                          : "Expert"}
                                                      </TableCell>
                                                    </TableRow>
                                                  );
                                                }
                                              )}
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    </AccordionDetails>
                                  </Accordion>
                                </Tooltip>
                              </div>
                            </React.Fragment>
                          );
                        }
                      )}
                  </Grid>
                </div>
              )}
              {!marksSection && (
                <h1 className="marks-section__else-content">
                  Select a Career Stage to View Details
                </h1>
              )}
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export const CustomTimeLineSeparator = () => (
  <TimelineSeparator className="separator_padding">
    <TimelineDot variant="outlined" className="timeline_dot" />
    <TimelineConnector />
  </TimelineSeparator>
);

export default CandidateProfile;
