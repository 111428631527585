import React from 'react';
import "../styles/Navbar.css";
import { Link } from "react-router-dom";
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import AccountCircleSharpIcon from '@mui/icons-material/AccountCircleSharp';
import TemporaryDrawer from './SideBar';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import logo from "../images/logo.png"
import { setProfile } from "../features/profileSlice";
import { useSelector ,useDispatch } from 'react-redux';
import {
  TextField,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  Grid,
  Box,
} from "@mui/material";

async function signOut() {
    try {
        await Auth.signOut();
        window.location.reload()
    } catch (error) {
        alert('error signing out: ', error);
    }
}


function ProfileDropDown() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { user } = useAuthenticator((context) => [context.user]);
  const profile= useSelector((state) => {
    return state.profile;
  });
  
  const dispatch = useDispatch();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloselogout=()=>{
    setAnchorEl(null);

    signOut();
  }

  const handleProfile=(event)=>{
    console.log(profile);
    // console.log(JSON.stringify(profile));
    // let a=profile.toString();
    // console.log(a);
    dispatch(setProfile(event.target.value));
    localStorage.setItem('myString',event.target.value);

  };

  const able =user.attributes['custom:switch'];

  return (
    <div>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <AccountCircleSharpIcon fontSize='large' style={{color:"#000"}}/>
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            width: '24ch',
          },
        }}
      >
        <MenuItem onClick={handleClose}>
            <Link to="/profile" style={{textDecoration:"none",color:"black"}}>
                Profile
            </Link>
        </MenuItem>
        {able=="true" && <MenuItem>
        <Select
                        name="template"
                        label="Choose Template"
                        value={profile}
                        onChange={handleProfile}
                      >
                          <MenuItem value="CloudAndDevops">
                          CloudAndDevops
                          </MenuItem>
                          <MenuItem value="DBA">
                          DBA
                          </MenuItem>
                          <MenuItem value="PE">
                          PE
                          </MenuItem>
                      </Select>
        </MenuItem>}
        <MenuItem onClick={handleCloselogout} >Logout</MenuItem>
      </Menu>
    </div>
  );
}

function Navbar(props) {
  const { user} = useAuthenticator((context) => [context.user]);
  return(

    <div className="nav-links">
      <div style={{display:"flex",gap:"20px",width:"fit-content"}}>
        <TemporaryDrawer/>

          <Link to="/" style={{ textDecoration: 'none',marginRight:"auto"}}  >
            <div className='nav-link'>
                <img alt="img" src="https://www.publicissapient.com/content/dam/ps-rebrand/brand/ps-logo-NEW.svg" style={{width:"70px",height:"32px"}}/>
            </div>
          </Link>
      </div>

        <div style={{display:"flex",alignItems:"center",justifyContent:"center",gap:"5px"}}>
          <img alt ="not found" src={logo} ></img>
          <p style={{fontSize:"22px"}}><b style={{color:"#FE414D"}}>PS</b> Hiring Probe <b style={{color:"#FE414D"}}>Form</b></p>
        </div>
        { user && <ProfileDropDown/>}
        {/* { user && <BlackButton onClick={signOut}>Sign out</BlackButton>} */}
    </div>
  );
}
export default Navbar;