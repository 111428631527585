import * as React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip } from '@mui/material';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect } from 'react';
import axios from 'axios';
import { proxy } from '../../proxy';



const backdrop = {
    visible : { opacity: 1 },
    hidden : { opacity: 0 }
}

const modal = {
    hidden: {
        y: "-100vh",
        opacity: 0
    },
    visible: {
        y: "50px",
        opacity: 1,
        transition: { delay: 0.5 }
    }
}

const CandidateModal = ({showCandidateModal, setCandidateModal, formid}) => {
    const { user} = useAuthenticator((context) => [context.user]);
    const [form,setForm] = useState([]);
    const [isLoading,setisLoading]=useState(true);

    const handleModal = () => {
        setCandidateModal(false);
    }

    const capitalizeFirst = str => {
        return str.charAt(0).toUpperCase() + str.slice(1);
      };
      const caps = str => {
        return str.split('.').map(capitalizeFirst).join(' ');
      };

    useEffect(()=> {
        axios.get(`${proxy}/api/candidatedetails/${formid}`, {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              'Authorization':user.signInUserSession.idToken.jwtToken,
              "Content-Type": "application/json",
              "Access-Control-Allow-Credentials": true,
            },
          }).then((res) => {
            setisLoading(false);
            console.log(res.data.result)
            setForm(res.data.result)
          }).catch((error)=>{
            setisLoading(false);
          });
    },[])
    
    return (
        <AnimatePresence exitBeforeEnter>
            {showCandidateModal && (
                <motion.div className='backdrop'
                    variants={backdrop}
                    initial="hidden"
                    animate="visible" 
                    exit="hidden" 
                >
                    <motion.div className='modal' 
                    variants={modal}
                    >
                        <div>
                            <Tooltip title="Close">
                                <CloseIcon style={{color:"#FE414D",position:'absolute',right:'15px', top:'15px', cursor:'pointer'}} onClick={handleModal}/>
                            </Tooltip>
                            <h3 style={{textAlign:'center'}}>Interview Overview</h3>
                            <TableContainer component={Paper} style={{width:"100%", padding:'20px', marginTop:'10px',marginBottom:'10px'}}>
                                <Table sx={{ miWidth: 250 }} size="small" aria-label="a dense table">
                                <TableBody>
                                    <TableRow>
                                        <TableCell style={{fontWeight:'bold'}}>Interviewer Name</TableCell>
                                        <TableCell>{caps(user.username)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{fontWeight:'bold'}}>Candidate Name</TableCell>
                                        <TableCell>{form.candidateName}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{fontWeight:'bold'}}>Candidate Email Id</TableCell>
                                        <TableCell>{form.emailAddress}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{fontWeight:'bold'}}>Interview Scheduled Date</TableCell>
                                        <TableCell>{new Date(form.interviewDate).toLocaleDateString('en-us',{weekday:"long", year:"numeric", month:"short", day:"numeric"})}</TableCell>
                                    </TableRow>
                                    {/* <TableRow>
                                        <TableCell>Form Submitted Date</TableCell>
                                        <TableCell>{toShortDateFormat(today)}</TableCell>
                                    </TableRow> */}
                                    <TableRow>
                                        <TableCell style={{fontWeight:'bold'}}>Evaluation Role</TableCell>
                                        <TableCell>{form.role}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{fontWeight:'bold'}}>Template</TableCell>
                                        <TableCell>{form.templateName}</TableCell>
                                    </TableRow>
                                    {/* <TableRow>
                                        <TableCell style={{fontWeight:'bold'}}>Gender</TableCell>
                                        <TableCell>{form.gender}</TableCell>
                                    </TableRow> */}
                                    <TableRow>
                                        <TableCell style={{fontWeight:'bold'}}>Form Filled Status</TableCell>
                                        <TableCell style={{color:form.formFilledStatus==true ? "green":"#FE414D"}}>{form.formFilledStatus == true ? "Evaluation Submitted":"Evaluation Ongoing"}</TableCell>
                                    </TableRow>
                                </TableBody>
                                </Table>
                            </TableContainer>
                            </div>
                    </motion.div>
                </motion.div>

            )}

        </AnimatePresence>
    )
}

export default CandidateModal;