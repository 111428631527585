import {Link, useHistory} from "react-router-dom"
import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ListItemIcon, Tooltip } from '@mui/material';
import {Typography} from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useDispatch } from 'react-redux/es/exports';
import { getFormData } from '../features/blockSlice';
import { useAuthenticator } from '@aws-amplify/ui-react';
import axios from "axios";
import { proxy } from "../proxy.js";
import { getTemplateData } from "../features/templateSlice";

function FadeMenu(props) {
  const style = {
    position: 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const { user } = useAuthenticator((context) => [context.user]);
  const [toBeDeletedTemplates, setToBeDeletedTemplates ] = React.useState([]);
  const dispatch=useDispatch();
  const history=useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    console.log(props.blockId)
    let x=window.confirm("Are You Sure You want to delete this block ? \n Note:The subsection will also be deleted")
    if(x){
      axios.delete(`${proxy}/api/buildingblocks/${props.blockId}`, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          'Authorization':user.signInUserSession.idToken.jwtToken,
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      }).then((res) => {
      //  setisLoading(false);
        console.log(res.data.result)

        setToBeDeletedTemplates(res.data.result);
        if(res.data.result.length===0){
          alert("Block Successfully Deleted");
          window.location.reload();
        }
        if(res.data.result.length>0){
          handleOpenModal();
        }

      }).catch((error)=>{
      //  setisLoading(false);
      console.log(error);
      });
    }

  };
  return (
    <div>
        <Tooltip title="Building Block Options">
            <MoreVertIcon
            id="fade-button"
            aria-controls={open ? 'fade-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}

            className="icon-hover"
        >
        </MoreVertIcon>
        </Tooltip>

      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <Link to={`/blocks/${props.blockId}`} style={{textDecoration:"none",color:"#000"}}>
            <MenuItem onClick={handleClose}>
                <ListItemIcon >
                    <RemoveRedEyeIcon fontSize="small" />
                </ListItemIcon>
            <Typography variant="inherit">View Building Block</Typography>
            </MenuItem>
        </Link>

        <MenuItem onClick={()=>{
        dispatch(getFormData({"id":props.blockId,"jwtToken":user.signInUserSession.idToken.jwtToken}))
        history.push("/block")}}>
            <ListItemIcon>
                <EditIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Edit Building Block</Typography>
        </MenuItem>
        <MenuItem onClick={handleClose}>
            <ListItemIcon onClick={handleDelete}>
                <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit" onClick={handleDelete}>Delete Building Block</Typography>
        </MenuItem>
      </Menu>
      <div>
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
          <div style={{width:"100%","textAlign":"center","color":"rgb(254, 65, 77)","fontSize":"x-large"}}>
            <p style={{fontSize:"x-large"}}>This Building Block cannot be deleted</p>
            <p style={{fontSize:"large"}}>Please delete the Below Template</p>
          </div>

            {
              toBeDeletedTemplates.map(({templateName,templateId})=>{
                return (
                  <Typography id="modal-modal-title" variant="h6" component="h2" style={{cursor:"pointer",color:"blue",textAlign:"center",
                  fontSize:"medium"
                }}>
                      <p onClick={()=>{
                        dispatch(getTemplateData({"id":templateId,"jwtToken":user.signInUserSession.idToken.jwtToken}))
                        history.push(`/addtemplate/${templateId}`)
                      }}>{templateName}</p>
                  </Typography>
                )
              })
            }
          </Box>
        </Modal>
      </div>
    </div>
  );
}

function SubSection(props){

  return(
    <div className="subsection-div">
      <div className="subsection-title">
          {props.subSection}
      </div>
      <div className="subsection-icons">
          <Link  to={`/${props.section}/${props.sectionMongoId}/${props.subSection}/${props.subSectionMongoId}`} style={{textDecoration:"none",color:"#000"}}>
              <Tooltip title="Edit SubSection">
                  <EditIcon className="icon-hover"/>
              </Tooltip>
          </Link>
          {/* <Tooltip title="Delete SubSection">
              <ClearIcon className="icon-hover"/>
          </Tooltip>  */}
          <FadeMenu blockId={props.blockId}/>      
      </div>
    </div>
  )
}

export default SubSection;