import { IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import "../styles/Traits.css"
function Traits(props) {
    return (
        <li className="traits-li">
            <div style={{width:"75%"}}>
                <p>{props.trait}</p>
            </div> 
            <IconButton aria-label="delete" size="large" style={{width:"25%"}} onClick={()=>{
                // dispatch(removeRow({index:props.index}))
            }}>
                <ClearIcon />
            </IconButton>       
        </li>
    );
  }
  export default Traits;