import {React} from 'react';
import '../styles/CreateFormRow.css';
export function DatabaseError(props){

    return (
        <div>
            <div className="create-form-row">
                <div className="create-form-row-title" style={{background:"#fe414d",color:"#fff"}}>
                    <h3>Application is down for regular maintainance, it will be up after 9 AM</h3>
                </div>
            </div>
            <img style={{width: "300px", height: "280px" , marginLeft:"500px", marginTop:"50px"}} src={require('../images/serverdown.png')} alt="logo" />
        </div>
    )
};