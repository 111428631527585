import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './app/store';
import { Authenticator } from '@aws-amplify/ui-react';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
const msalInstance = new PublicClientApplication(msalConfig);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <Authenticator.Provider>
          <App />
          </Authenticator.Provider>
          </MsalProvider>
      </React.StrictMode>
    </BrowserRouter>
  </Provider>


);


