
import { changedisplayingindex } from "../features/blockSlice"
import { useDispatch } from "react-redux";
import "../styles/Block.css"
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Engineering from "@mui/icons-material/Engineering";
// import DraftsIcon from '@mui/icons-material/Drafts';
function RoleButton(props) {
    const dispatch=useDispatch();
    return(
        <ListItem>
            <ListItemButton onClick={()=>{
                        dispatch(changedisplayingindex({"index":props.index}))
                    }}>
                        <ListItemIcon>
                            <Engineering />
                        </ListItemIcon>
                        <ListItemText primary={props.role} />
            </ListItemButton>
            <Divider/>
        </ListItem>        
    )

}
export default RoleButton;