import Delete from "@mui/icons-material/Delete";
import {proxy} from "../proxy.js";
import axios from "axios";
import { useAuthenticator } from "@aws-amplify/ui-react";
function TemplateComment(props) {
    const { user } = useAuthenticator((context) => [context.user]);
      const handleDelete = () => {
        axios.put(`${proxy}/api/templateComment`,{templateId:props.templateId,commentId:props.commentId}, {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            'Authorization':user.signInUserSession.idToken.jwtToken,
            "Content-Type": "application/json",
            "Access-Control-Allow-Credentials": true,
          },
        }).then((res) => {
        //  setisLoading(false);
          console.log(res.data.result);
          window.location.reload();
        }).catch((error)=>{
        //  setisLoading(false);
        console.log(error);
      });
    };
    return(
        <div className="template-comment">
            <div className="template-comment-header">
                <div className="template-comment-username">
                    {props.username}
                </div>
                <div className="template-comment-time">
                Posted on: {props.postedDate}
                </div>
                { props.username ==user.username &&  <Delete style={{marginLeft:"auto",color:"red",cursor:"pointer"}} onClick={handleDelete}/>}
            </div>
            <div className="template-comment-text">
            {props.comment}
            </div>
        </div>
    )

}
export default TemplateComment;