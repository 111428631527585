// export const proxy="http://apilambda-1540578608.us-east-1.elb.amazonaws.com";
// export const proxy="http://localhost:5000";
// https://hiringprobeformapi.psengineering.io
// export const proxy="https://y9n2ftqmzb.execute-api.us-east-1.amazonaws.com/dev";
// export const proxy="https://hiringprobeformapi.psengineering.io";
// export const proxy="http://localhost:5000";
export const proxy="https://ngmsrqs9yd.execute-api.us-east-1.amazonaws.com/Dev"
// export const proxy="https://muar0esb8d.execute-api.us-east-1.amazonaws.com/dev";
// export const proxy="https://zh4255zf1a.execute-api.us-east-1.amazonaws.com/dev";
// export const new proxy ="https://y7adrsgkwk.execute-api.us-east-1.amazonaws.com/dev"
// https://muar0esb8d.execute-api.us-east-1.amazonaws.com/dev
// export const proxy="https://id47286od2.execute-api.us-east-1.amazonaws.com/dev";
// export const proxy="https://wi5zy3fu45.execute-api.us-east-1.amazonaws.com/dev";
// export const proxy="https://qwhiy56exj.execute-api.us-east-1.amazonaws.com/dev";
// export const proxy="https://d5197ee426.execute-api.us-east-1.amazonaws.com/dev";
// export const proxy="https://6q6fei0c1a.execute-api.us-east-1.amazonaws.com/dev"
// export const proxy="https://szptb2wdc8.execute-api.us-east-1.amazonaws.com/nonprod";
// export const proxy="https://hiringprobeformapi.psengineering.io";
// export const proxy="https://qax2cn5dt4.execute-api.us-east-1.amazonaws.com/dev"
// https://y9n2ftqmzb.execute-api.us-east-1.amazonaws.com/dev
// export const proxy2="http://apilambda-1540578608.us-east-1.elb.amazonaws.com";
// https://0v28vq3joj.execute-api.us-east-1.amazonaws.com/dev