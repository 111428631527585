import React, { useState } from "react";
import axios from "axios";
import { proxy } from "../../proxy";
import { useAuthenticator } from "@aws-amplify/ui-react";

const UploadBulkData = () => {
  const { user } = useAuthenticator((context) => [context.user]);
  const [file, setFile] = useState();

  const handleChange = (event) => {
    setFile(event.target.files[0]);
  };

  function handleSubmit(event) {
    event.preventDefault();
    const url = `${proxy}/api/fileupload`;
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", file.name);
    const config = {
      withCredentials: true,
      headers: {
        Authorization: user.signInUserSession.idToken.jwtToken,
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Credentials": true,
      },
    };

    axios.post(url, formData, config).then((response) => {
      console.log("File Uploaded", response.data);
    });
  }

  return (
    <div>
      <h1>File Upload</h1>
      <form onSubmit={handleSubmit}>
        <input type="file" onChange={handleChange} />
        <button type="submit">Upload</button>
      </form>
    </div>
  );
};

export default UploadBulkData;
