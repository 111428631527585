import React from "react";
import "../styles/CreateFormRow.css";
import FormColumn from "./FormColumn";
function FormRow(props) {
  const columnboxes = props.roleData.map((roledata) => {
    let found = false;
    const foundRole = props.data.variants.map(({ roleId, expectations }) => {
      if (roledata._id === roleId) {
        found = true;
        return (
          <FormColumn
            nameOfColumn={roledata.role}
            expectations={expectations}
            isPresent={true}
          />
        );
      }
      return <div></div>;
    });
    if (!found) {
      return (
        <FormColumn
          nameOfColumn={roledata.role}
          expectations={["This Variant is not created"]}
          isPresent={false}
        />
      );
    } else {
      return foundRole;
    }
  });
  return (
    <div>
      <div className="create-form-row">
        <div className="create-form-row-title">
          <h3>{props.data.sectionName}</h3>
        </div>
        <div className="create-form-row-columns-box">
          <div
            className="create-form-column form-subsection"
            style={{ borderRight: "0px solid black" }}
          >
            <div className="create-form-column-heading-box">
              <p style={{ color: "black" }}>Sub Section</p>
            </div>
            <div
              className="create-form-column-content-box"
              style={{ textAlign: "center" }}
            >
              <p>{props.data.subSectionName}</p>
            </div>
          </div>
          <FormColumn
            nameOfColumn={"Probe Areas"}
            expectations={props.data.probeAreas}
            isPresent={true}
          />
          <FormColumn
            nameOfColumn={"Recomended Probe Areas"}
            expectations={props.data.recommendedProbeAreas}
            isPresent={true}
          />
          {columnboxes}
          <div
            className="create-form-column"
            style={{ border: "0px solid black" }}
          >
            <div
              className="create-form-column-heading-box"
              style={{ border: "0px solid black" }}
            >
              <p style={{ color: "black", border: "0px solid black" }}>
                Weightage
              </p>
            </div>
            <div
              className="create-form-column-content-box"
              style={{ border: "0px solid black", textAlign: "center" }}
            >
              <p>{props.data.weightage}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default FormRow;
