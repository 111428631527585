import { useAuthenticator } from "@aws-amplify/ui-react";
import { TextField } from "@mui/material";
import "../styles/SectionSubSectionEditor.css";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import BlackButton from "./BlackButton";
import { useState } from "react";
import axios from "axios";
import Loader from "./Loader";
import { proxy } from "../proxy";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
function SectionAdder() {
  const history = useHistory();
  const { user } = useAuthenticator((context) => [context.user]);
  const [sectionName, setSectionName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const profile= useSelector((state) => {
    return state.profile;
  });
  async function senddata() {
    document.getElementById("section-submit").disabled = true;
    setIsLoading(true);
    axios
      .post(
        `${proxy}/api/section`,
        { section: `${sectionName}`, profile: `${profile}` },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            Authorization: user.signInUserSession.idToken.jwtToken,

            "Content-Type": "application/json",
            "Access-Control-Allow-Credentials": true,
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        //   console.log(res.data);
        alert(`Section ${sectionName} successfully added !!`);
        history.push("/sections");
      })
      .catch((error) => {
        setIsLoading(false);

        alert("error");
        console.log(error);
      });
  }
  return (
    <div>
      {isLoading && <Loader />}
      {!isLoading && (
        <div>
          <div className="sections-header">
            <div className="sections-title-header">
              <p>
                Add Your Data here <ArrowDownwardIcon />{" "}
              </p>
            </div>
          </div>
          <div className="section-editor-components">
            <div className="section-editor-component">
              <div className="section-editor-component-title">
                <p>Add Your Section</p>
              </div>
              <TextField
                fullWidth
                onChange={(e) => setSectionName(e.target.value)}
                validate
                placeholder="Add the Section Name"
              />
            </div>
            <BlackButton
              id="section-submit"
              variant="contained"
              style={{ marginTop: "10px" }}
              onClick={() => {
                document.getElementById("section-submit").disabled = true;
                senddata();
                document.getElementById("section-submit").disabled = false;
              }}
            >
              {" "}
              Submit
            </BlackButton>
          </div>
        </div>
      )}
    </div>
  );
}
export default SectionAdder;
