import * as React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


const backdrop = {
    visible : { opacity: 1 },
    hidden : { opacity: 0 }
}

const modal = {
    hidden: {
        y: "-100vh",
        opacity: 0
    },
    visible: {
        y: "50px",
        opacity: 1,
        transition: { delay: 0.5 }
    }
}

const ProficiencyModal = ({showProficiencyModal, setProficiencyModal}) => {
    const { user} = useAuthenticator((context) => [context.user]);
    

    const handleModal = () => {
        setProficiencyModal(false);
    }

    
    return (
        <AnimatePresence exitBeforeEnter>
            {showProficiencyModal && (
                <motion.div className='backdrop'
                    variants={backdrop}
                    initial="hidden"
                    animate="visible" 
                    exit="hidden" 
                >
                    <motion.div className='modal' 
                    variants={modal}>
                        <div>
                            <Tooltip title="Close">
                                <CloseIcon style={{color:"#FE414D",position:'absolute',right:'15px', top:'15px', cursor:'pointer'}} onClick={handleModal}/>
                            </Tooltip>
                            <h3 style={{textAlign:'center'}}>Proficiency Matrix</h3>
                            <TableContainer component={Paper} style={{width:"100%", padding:'20px', marginTop:'10px',marginBottom:'10px'}}>
                                <Table sx={{ minWidth: 250 }} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{fontWeight:'bold'}}>Ratings</TableCell>
                                            <TableCell style={{fontWeight:'bold'}} align="left">Description</TableCell>
                                            </TableRow>
                                        </TableHead>
                                    <TableBody>
                                        <TableRow>
                                        <TableCell  align="left" component="th" scope="row">0</TableCell>
                                        <TableCell align="left" component="th" scope="row">{"No Experience - No Knowledge"}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell align="left" component="th" scope="row">1</TableCell>
                                        <TableCell align="left" component="th" scope="row">{"Beginner - Basic Theoretical Knowledge"}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell align="left" component="th" scope="row">2</TableCell>
                                        <TableCell align="left" component="th" scope="row">{"Intermediate - Experience (At Least 6 months)"}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell align="left" component="th" scope="row">3</TableCell>
                                        <TableCell align="left" component="th" scope="row">{"Proficient - Strong process design experience"}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell  align="left" component="th" scope="row">4</TableCell>
                                        <TableCell align="left" component="th" scope="row">{"Expert - Expert in design experience"}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </motion.div>
                </motion.div>

            )}

        </AnimatePresence>
    )
}

export default ProficiencyModal;