// import TemplateDynamicComponents from './TemplateDynamicComponents';
import TemplateStaticComponents from './TemplateStaticComponents';
import "../styles/Template.css"
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import Loader from './Loader';
import { Tooltip } from '@mui/material';
import Fab from '@mui/material/Fab';
function Template() {
    const {templateid}=useParams()
    const isLoading=useSelector((store)=>{
        return store.template.isLoading
    })
    const validation=useSelector((store)=>{
        let totalscore=0.0;
        let valid=true;
        for(let i=0;i<store.template.template.length;i++){
            if(store.template.template[i].weightage<=0.0 ||store.template.template[i].weightage>100.0  ){
                valid=false;
            }
            totalscore+=Number(store.template.template[i].weightage);
        }
        return {"totalScore":totalscore,"isValid":valid}
    })
    return(
        <div className='template-box'>
            {isLoading && <Loader/>}
            {!isLoading && <TemplateStaticComponents templatemongoid={templateid}/>}
            <Tooltip title="Total Weightage">
                <Fab aria-label="add" style={  {position: 'fixed',bottom:16,right:16,backgroundColor:"rgb(254, 65, 77)"}}>
                       <span style={{color:"white"}}>{validation.totalScore} </span>  
                </Fab>
            </Tooltip>
        </div>
    )

}
// <TemplateDynamicComponents/>
export default Template;