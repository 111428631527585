import { useAuthenticator } from "@aws-amplify/ui-react";
import "../styles/Block.css";
import { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import BlockKeyPointsViewer from "./BlockKeyPointsViewer";
import { proxy } from "../proxy";
function BlockView() {
  const { user } = useAuthenticator((context) => [context.user]);
  const { id } = useParams();
  let initialState = {
    section: "Loading...",
    section_id: "",
    subSection_id: "",
    subSection: "Loading...",
    probeAreas: ["Loading..."],
    recommendedProbeAreas: ["Loading...."],
    variants: [],
  };
  const [data, setData] = useState(initialState);
  useEffect(() => {
    // console.log("blockview")
    axios
      .get(`${proxy}/api/buildingblocks/${id}`, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          Authorization: user.signInUserSession.idToken.jwtToken,
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      })
      .then((res) => {
        //   console.log(res.data);
        setData(res.data);
        //   console.log("data",data);
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line
  }, []);

  const expectationstiles = data.variants.map(
    ({ role, expectations }, index) => {
      return (
        <BlockKeyPointsViewer
          key={index}
          role={role}
          index={index}
          expectations={expectations}
        />
      );
    }
  );

  const probeTiles = data.probeAreas.map((probeArea, index) => {
    return (
      <div className="block-probearea-tile">
        <div className="block-probearea-text"> {probeArea}</div>
      </div>
    );
  });

  const recommendedProbeTiles = Object.keys(data.recommendedProbeAreas).map(
    (key, index) => {
      return (
        <div className="block-probearea-tile" key={index}>
          <div className="block-probearea-text">{key}</div>
        </div>
      );
    }
  );

  return (
    <div className="block-review-page" style={{ marginTop: "50px" }}>
      <div
        className="block-review"
        style={{ display: "flex", flexDirection: "column", gap: "10px" }}
      >
        <div className="block-selectors-row">
          <div className="block-selectors-col">
            <p className="block-heading">Section</p>
            <div>{data.section}</div>
          </div>
          <div className="block-selectors-col">
            <p className="block-heading">Sub-Section</p>
            <div>{data.subSection}</div>
          </div>
        </div>
        <div className="block-selectors-row">
          <div className="block-selectors-col">
            <h2 style={{ textAlign: "left", color: "grey", width: "100%" }}>
              Probe Areas
            </h2>
            <div className="block-probeareas-tiles">
              {probeTiles.length === 0 && <h3>No probe Areas are selected</h3>}
              {probeTiles}
            </div>
          </div>

          <div className="block-selectors-col">
            <h2 style={{ textAlign: "left", color: "grey", width: "100%" }}>
              Recommended Probe Areas
            </h2>
            <div className="block-probeareas-tiles">
              {recommendedProbeTiles.length === 0 && (
                <h3>No recommended probe Areas are selected</h3>
              )}
              {recommendedProbeTiles}
            </div>
          </div>
        </div>
        <h2 style={{ textAlign: "center", color: "grey" }}>
          Roles and Expectations
        </h2>
        <div className="block-keypoints-review">
          {expectationstiles.length === 0 && <h3>No Roles are Added</h3>}
          {expectationstiles}
        </div>
      </div>
    </div>
  );
}
export default BlockView;
