import { Avatar, Card, CardContent, Divider, Grid, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TextField from '@mui/material/TextField';
import BlackButton from './BlackButton';
import {proxy} from "../proxy";
import axios from "axios";
import { Loader, useAuthenticator } from '@aws-amplify/ui-react';
import DoughnutChart from './charts/DoughnutChart';
import "./charts/Reports.css";
import RectangleIcon from '@mui/icons-material/Rectangle';
import { useLocation } from "react-router-dom";

function InterviewerProfile(){
    let date = new Date();
    date.setDate(date.getDate() - 7);

    const interviewLocation = useLocation();
    const [ interviewerData, setInterviewerData] = useState([]);
    const [startValue, setStartValue] = useState(date);
    const [endValue, setEndValue] = useState(new Date());
    const { user} = useAuthenticator((context) => [context.user]);
    useEffect(() => {
        handleReport();
    },[])

    const capitalizeFirst = str => {
        return str.charAt(0).toUpperCase() + str.slice(1);
      };
    const caps = str => {
        return str.split('.').map(capitalizeFirst).join(' ');
      };

    async function handleReport(){
        try { 
            const res = await axios.post(`${proxy}/api/cxoreports`,
                {"interviewerName": interviewLocation.state.interviewerName,
                "startDate" : startValue,
                "endDate" : endValue },
                {
                withCredentials: true,
                headers: {
                    'Authorization':user.signInUserSession.idToken.jwtToken,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Credentials": true,
                },
            });
            //console.log("panelist data\n",res);.
            //console.log("Data :--->",res.data.results)
            setInterviewerData(res.data.results);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div>
            <Grid container spacing={4}>
                <Grid item lg={4} sm={6} xl={4} xs={12}>
                    <Card height={'auto'} className="root" style={{margin:'20px'}}>
                        <CardContent>
                            <div className="details">
                            <Avatar className="avatar" src="../../images/profileimage.jpg" />
                            <div>
                                <Typography variant="h4" className="name">
                                Romu Tiwari
                                </Typography>
                                <Typography color="textSecondary">Associate</Typography>
                            </div>
                            </div>
                            <div className="progress">
                            <Divider className="divider" />
                            <div className="textRow">
                                <Typography className="dateText" color="textSecondary" variant="body1" component="span">
                                User ID
                                </Typography>
                                <Typography className="smallBoldText" variant="body1" component="span">
                                {interviewLocation.state.interviewerName}
                                </Typography>
                            </div>
                            </div>
                        </CardContent>
                     </Card>
                </Grid>
                <Grid item lg={8} sm={6} xl={4} xs={12}>
                <div style={{width:'100%', margin:'30px'}}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DesktopDatePicker
                                            inputFormat='DD/MM/YYYY'
                                            maxDate={new Date()}
                                            label="Start Date"
                                            renderInput={(params) => <TextField {...params} name="startDate" sx={{width:150,marginRight:2}}/>}
                                            value={startValue}
                                            onChange={(newValue) => {
                                                setStartValue(new Date(newValue).toISOString());
                                            }}
                                            /> 
                                            <DesktopDatePicker
                                                inputFormat='DD/MM/YYYY'
                                                minDate={startValue}
                                                maxDate={new Date()}
                                                label="End Date"
                                                renderInput={(params) => <TextField {...params} name="endDate" sx={{width:150}}/>}
                                                value={endValue}
                                                onChange={(newValue) => {
                                                    setEndValue(new Date(newValue).toISOString());
                                                }}
                                            />
                                      </LocalizationProvider>
                                      <BlackButton style={{}} onClick={handleReport}>Filter</BlackButton>
                    </div>
                    <div style={{width:'100%', margin:'20px'}}>
                    <div style={{width:'40%'}}>
                       <RectangleIcon style={{color:'rgba(54, 162, 235, 1)'}}/> Qualified
                       <RectangleIcon style={{color:'rgba(255, 99, 132, 0.2)'}}/> Disqualified                             
                       <RectangleIcon style={{color:'rgba(255, 206, 86, 1)'}}/> Reiteration
                    </div>
                    <div className="chart-row">
                    { interviewerData && interviewerData.map(({Disqualified,Qualified,Reiteration,interviewerName})=>{
                                        return (
                                            <div className='chart-box' style={{width:'250px'}}>
                                            <DoughnutChart chartData={{
                                                datasets: [
                                                {
                                                    label: `${interviewerName}`,
                                                    data: [Disqualified, Qualified, Reiteration],
                                                    backgroundColor: [
                                                    'rgba(255, 99, 132, 0.2)',
                                                    'rgba(54, 162, 235, 0.2)',
                                                    'rgba(255, 206, 86, 0.2)'
                                                    ],
                                                    borderColor: [
                                                    'rgba(255, 99, 132, 1)',
                                                    'rgba(54, 162, 235, 1)',
                                                    'rgba(255, 206, 86, 1)'
                                                    ],
                                                    borderWidth: 2,
                                                },
                                                ],
                                            }}/>
                            <h5 style={{textAlign:"center",color:"grey",fontSize:"small"}}>{`${caps((interviewerName).replace("."," "))}`}</h5>
                    </div>  
                                        ) 
                                    } )
                                }   
                    </div>                      
                    </div>                             
                </Grid>
                { interviewerData && interviewerData.map(({Disqualified,Qualified,Reiteration})=>{
                                        return (
                <Grid item lg={12} sm={6} xl={4} xs={12}>
                    <div className={{}}>
                        <Grid className={{}} item lg={4} sm={3} xl={6} xs={12}>
                            <div style={{backgroundColor:'grey', borderRadius:'25px', width:'50%', height:'100px', margin:'20px'}}>
                                <Typography>
                                    Qualified
                                </Typography>
                                <Typography>
                                    {Qualified}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid className={{}} item lg={4} sm={3} xl={6} xs={12}>
                            <div style={{backgroundColor:'grey', borderRadius:'25px',width:'50%', height:'100px', margin:'20px'}}>
                                <Typography>
                                    Disqualified
                                </Typography>
                                <Typography>
                                    {Disqualified}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid className={{}} item lg={4} sm={3} xl={6} xs={12}>
                            <div style={{backgroundColor:'grey', borderRadius:'25px',width:'50%', height:'100px', margin:'20px'}}>
                                <Typography>
                                    Reiteration
                                </Typography>
                                <Typography>
                                    {Reiteration}
                                </Typography>
                            </div>
                        </Grid>
                    </div>
                </Grid>
                )
            }
            )
        }
            </Grid>
            
        </div>
    )
}

export default InterviewerProfile;