import { Box, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { useTheme } from "@mui/material/styles";


const MultiSelectDropdown = (props) => {

  const [selected, setSelected] = useState([]);
  const theme = useTheme();

  const handleChangeRole = (event) => {
    const {
      target: { value },
    } = event;
    // console.log(value);
    setSelected(typeof value === "string" ? value.split(",") : value);
    props.handleRolesFilters(value);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
    
  function getStyles(name, role, theme) {
    return {
      fontWeight:
        role.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

    return (
        <React.Fragment>
            <FormControl sx={{ m: 1, width: 500 }}>
                    <InputLabel id="demo-multiple-chip-label">
                      Select Roles
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      multiple
                      value={selected}
                      onChange={handleChangeRole}
                      input={
                        <OutlinedInput
                          id="select-multiple-chip"
                          label="Select Roles"
                        />
                      }
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {props.roleData.map(({ _id, role }) => (
                        <MenuItem
                          key={_id}
                          value={role}
                          style={getStyles(_id, role, theme)}
                        >
                          {role}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
        </React.Fragment>
    )

}

export default MultiSelectDropdown;