import { useAuthenticator } from '@aws-amplify/ui-react';
import { TextField } from "@mui/material";
import "../styles/SectionSubSectionEditor.css"
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useParams } from "react-router-dom";
import BlackButton from "./BlackButton"
import { useState } from "react";
import Loader from "./Loader";
import axios from "axios"
import { proxy } from "../proxy";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
function SubSectionEditor(){
    const { user} = useAuthenticator((context) => [context.user]);
    const { section,sectionid,subsection,subsectionid } = useParams();
    const [currentsubSection,setcurrentSubSection]=useState();
    const [isLoading,setIsLoading]=useState(false)
    const history = useHistory()
    async function  senddata(){
        // console.log(currentsubSection,section,sectionid,subsection,subsectionid)
        axios.put(`${proxy}/api/subsection`,{"_id":`${sectionid}`,"subSectionId":`${subsectionid}`,"subSection":`${currentsubSection}`}, {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              'Authorization':user.signInUserSession.idToken.jwtToken,
              "Access-Control-Allow-Credentials": true,
            },
          }).then((res) => {
                setIsLoading(false);
            //   console.log(res.data);
              alert("Subsection Edited")
              history.push('/sections')
          }).catch((error)=>{
            setIsLoading(false);
            alert("error")
            console.log(error)
          });
          setIsLoading(true);
    };
    return(
        <div style={{width:"100%"}}>
            {!isLoading && 
            <div>
                <div className="sections-header">
                    <div className="sections-title-header">
                        <p>Add Your Data here <ArrowDownwardIcon/> </p>
                    </div>
                </div>
                <div className="section-editor-components">
                    <div className="section-editor-component">
                        <div className="section-editor-component-title">
                            <p>Current Section</p>
                        </div>
                        <TextField fullWidth disabled value={section}/>
                    </div>
                    <div className="section-editor-component">
                        <div className="section-editor-component-title">
                            <p>Previous Subsection</p>
                        </div>
                        <TextField fullWidth disabled value={subsection}/>

                    </div>
                    <div className="section-editor-component">
                        <div className="section-editor-component-title">
                            <p>Edited Subsection Name</p>
                        </div>
                        <TextField fullWidth onChange={(e)=>setcurrentSubSection(e.target.value)}/>
                    </div>
                    <BlackButton id="subsection-submit" variant="contained" style={{marginTop:"10px"}} onClick={()=>{
                            document.getElementById('subsection-submit').disabled=true;
                            senddata()
                            document.getElementById('subsection-submit').disabled=false;
                        }}> Submit</BlackButton>
                </div>
            </div>}
            {isLoading && <Loader/>}
        </div>

    )
}
export default SubSectionEditor;