
import { IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch } from 'react-redux'
import { removeColumn } from '../features/adminFormSlice';
function ColumnTitleDisplayer(props){
    const stying={
        display:"flex",
        width:"fitContent",
        border:"1px solid grey",
        padding:"5px",
        fontSize:"10px",
        justifyContent:"spaceAround",
        borderRadius:"10px",

    }
    const dispatch=useDispatch();
    return(
        <div style={stying}>
            <h3>{props.role}</h3>
            <IconButton aria-label="delete" size="small" onClick={()=>{
                dispatch(removeColumn({"index":props.index}))
            }}>
                <ClearIcon />
            </IconButton>
        </div>
    )
}
export default ColumnTitleDisplayer