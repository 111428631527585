import { useState } from "react";
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import { Button } from "@mui/material";
import {proxy} from "../proxy.js";
import axios from "axios";
import { useAuthenticator } from "@aws-amplify/ui-react";
function TemplateAddComment(props){
    const { user } = useAuthenticator((context) => [context.user]);
    const [comment,setComment]=useState("");
    const [disable,setDisable]=useState(false);
    const handleChange = (event) => {
        setComment(event.target.value);
      };
      const sendComment = () => {
        setDisable(true);
        if(comment==""){
            alert("comment cannot be empty");
            return;
        }
        axios.post(`${proxy}/api/templateComment`,{"templateId":props.templateId,"username":user.username,"comment":comment}, {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            'Authorization':user.signInUserSession.idToken.jwtToken,
            "Content-Type": "application/json",
            "Access-Control-Allow-Credentials": true,
          },
        }).then((res) => {
        //  setisLoading(false);
            setDisable(false);
          console.log(res.data.result);
          window.location.reload();
        }).catch((error)=>{
        //  setisLoading(false);
            setDisable(false);

        console.log(error);
      });
    };
    return(
        <div style={{display:"flex",width:"100%",height:"100%",padding:"5px"}}>
            <TextField style={{width:"100%",height:"100%"}} id="outlined-basic" label="Write Comments" variant="standard" onChange={handleChange}/>
                <Button style={{color:"white",height:"100%",color:"#33bfff",alignSelf:"flex-end"}} onClick={sendComment} disabled={disable}><SendIcon/></Button>
            </div>

    )
}
export default TemplateAddComment;
         // <div style={{display:"flex",width:"50px",height:"100%",textAlign:"center",background:"#2196f3"}}>