import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';


export default function Loader() {
  return (
    <div style={{display:"flex",justifyContent:"center",width:"100%",marginTop:"20%"}}>
      <CircularProgress />
    </div>
  );
}