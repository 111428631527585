import BlackButton from "./BlackButton";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { proxy } from "../proxy.js";
import axios from "axios";

function TemplateSubmit(props) {
  const { user } = useAuthenticator((context) => [context.user]);
  const history = useHistory();
  // let data={};
  const dispatch = useDispatch();
  const profile= useSelector((state) => {
    return state.profile;
  });
  const data = useSelector((store) => {
    return store.template;
  });
  async function sendTemplateData() {
    try {
      const response = await axios.put(
        `${proxy}/api/template`,
        {
          data: data,
          id: props.templateMongoId,
          user: user.username,
          profile: profile,
        },
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: user.signInUserSession.idToken.jwtToken,
            "Access-Control-Allow-Credentials": true,
          },
        }
      );
      //const response2 = handleSendEmail();
      //console.log(response2)
      console.log("Email Sent Successfully");
      history.push("/templates");
      console.log("Data Pushed to database");
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        marginTop: "10px",
      }}
    >
      <BlackButton
        variant="contained"
        style={{ width: "100px", margin: "auto" }}
        onClick={sendTemplateData}
      >
        {" "}
        Submit{" "}
      </BlackButton>
    </div>
  );
}
export default TemplateSubmit;
