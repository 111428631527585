import * as React from 'react';
// import { useSelector } from "react-redux"
import "../styles/Block.css"
import ClearIcon from '@mui/icons-material/Clear';
import {removeKeyPoint} from "../features/blockSlice"
import { useDispatch } from 'react-redux';
function BlockKeyPoint(props) {
    const dispatch=useDispatch()
        return(
            <div className="block-keypoint-tile">
                
                    <div className='block-keypoint-index'>{props.pointindex+1}.</div>
                    <div className='block-keypoint-text'> {props.point}</div>
                    <ClearIcon className="block-keypoint-clear" style={{width:"10%"}}
                    onClick={()=>{
                        dispatch(removeKeyPoint({"roleindex":props.roleindex,"index":props.pointindex}))
                    }}/>
            </div>
        )

}

export default BlockKeyPoint;