import * as React from 'react';
import "../styles/Block.css"
import BlockKeyPointReview from './BlockKeyPointReview';
function BlockKeyPointsViewer(props) {
    

    const pointTiles=props.expectations.map((text,index) => {
                return(
                    <BlockKeyPointReview key = {index} roleindex={props.index} pointindex={index} point={text}/>
                )
            })
   
    return(
        <div style={{display:"flex",flexDirection:"column"}}>
            <div className='block-keypoints-div' style={{height:"100%"}}>
                <div className="block-keypoints-header">
                    <div className='block-keypoints-title'> {props.role}</div>
                </div>
                <ol className="block-keypoints-tiles">
                    {pointTiles}
                </ol>
                {pointTiles.length===0 && <div className="block-keypoints-tiles">
                <p style={{color:"grey",fontSize:"x-large",margin:"auto"}}>No Expectations added</p>
            </div>}

            </div>
        </div>

  )

}

export default BlockKeyPointsViewer;