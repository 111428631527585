import {React} from 'react';
import '../styles/CreateFormRow.css';
import InterviewerFormColumn from './InterviewerFormColumn';
export function InterviewerFormPhone(props){

    return (
        <div>
            <div className="create-form-row">
                <div className="create-form-row-title" style={{background:"#fe414d",color:"#fff"}}>
                    <h3>Feedback</h3>
                </div>
                <div className='create-form-row-columns-box'>
                    <div style={{borderRight:"0px solid black", marginLeft: "20px" , marginRight: "20px"}}>
                        {/* <div className='create-form-column-heading-box' style={{background:"#F9F9F9"}}>
                            <p style={{color:"black"}}>{props.data.feedback}</p>
                        </div> */}
                        <div style={{textAlign:"center",background:"#F9F9F9"}}>
                            <p style={{fontSize:"large"}}>{props.data.feedback}</p>
                        </div>
                    </div>
                </div>
                <div className="create-form-row-title" style={{background:"#fe414d",color:"#fff"}}>
                    <h3>Areas of Improvement</h3>
                </div>
                <div className='create-form-row-columns-box'>
                    <div  style={{borderRight:"0px solid black", marginLeft: "20px" , marginRight: "20px"}}>
                        {/* <div className='create-form-column-heading-box' style={{background:"#F9F9F9"}}>
                            <p style={{color:"black"}}>{props.data.feedback}</p>
                        </div> */}
                        <div style={{textAlign:"center",background:"#F9F9F9"}}>
                            <p style={{fontSize:"large"}}>{props.data.AreasOfImprovement}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
};