import { useAuthenticator } from "@aws-amplify/ui-react";
import React from "react";
import "../styles/CreateForm.css";
import { useParams } from "react-router-dom";
import FormRow from "./FormRow";
import { useState, useEffect } from "react";
import axios from "axios";
import Loader from "./Loader";
import { proxy } from "../proxy";
import { useSelector } from "react-redux";

function FormView() {
  const { user } = useAuthenticator((context) => [context.user]);
  const { templateid } = useParams();
  const [data, setData] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [templateName, setTemplateName] = useState("");
  const [roleData, setRoleData] = useState([]);
  const profile= useSelector((state) => {
    return state.profile;
  });
  useEffect(() => {
    axios
      .put(
        `${proxy}/api/template_titles`,
        { _id: templateid },
        {
          withCredentials: true,
          headers: {
            Authorization: user.signInUserSession.idToken.jwtToken,
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Credentials": true,
          },
        }
      )
      .then((res) => {
        setisLoading(false);
        // console.log("result form",res.data.result);
        setData(res.data.result);
        setTemplateName(res.data.templateName);
        // console.log("data form",data)
      })
      .catch((error) => {
        setisLoading(false);
        alert("error");
      });
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    axios
      .get(`${proxy}/api/role`, {
        withCredentials: true,
        headers: {
          Authorization: user.signInUserSession.idToken.jwtToken,
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      })
      .then((res) => {
        setRoleData(res.data.result);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const rows = data.map((rowdata, index) => {
    // console.log("rowdata",rowdata)
    return (
      <FormRow key={index} index={index} data={rowdata} roleData={roleData} />
    );
  });

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          margin: "auto",
        }}
      >
        <h1>{templateName === "" ? "Not Selected" : templateName}</h1>
      </div>
      {isLoading && <Loader />}
      <div className="form-wrapper">
        {!isLoading && (
          <div className="createform" style={{ margin: "auto" }}>
            <div
              style={{ display: "flex", flexDirection: "Column", gap: "80px" }}
            >
              {rows}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default FormView;
