import * as React from 'react'
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useParams } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import Loader from "./Loader";
import { proxy } from "../proxy";
import { useEffect } from 'react';
import {initialiseState} from "../features/interviewerFormEditSlice";
import { useDispatch } from "react-redux";
import InterviewerFormRowEdit from './InterviewerFormRowEdit'
import { InterviewerFormEditCandidateTable } from './InterviewerFormTables';
import { InterviewerProficiencyTable } from './InterviewerFormTables';
import Modal from 'react-modal';
import { useSelector } from "react-redux";
import { Button } from '@mui/material';
import InterviewerFormSubmitEdit from './InterviewerFormSubmitEdit';
function InterviewFormEdit() {
    const dispatch=useDispatch();
    const { user} = useAuthenticator((context) => [context.user]);
    const [isLoading,setIsLoading]=useState(false);
    const { formId } = useParams();
    const [data,setData]=React.useState([])
    const [modalIsOpenCandidate, setIsOpenCandidate] = React.useState(false);
    const [modalIsOpenMarks, setIsOpenMarks] = React.useState(false);
    const roleData = useState([]);
    useEffect(() => {
        //setIsLoading(true);
        axios.post(`${proxy}/api/forms`,{"id":formId}, {
            withCredentials: true,
            headers: {
              'Authorization':user.signInUserSession.idToken.jwtToken,
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Credentials": true,
            },
          }).then((res) => {
            console.log(res.data);
              dispatch(initialiseState(res.data.results));
              setData(res.data.results);
             // setIsLoading(false);
          }).catch((error)=>{
           // setIsLoading(false);
            console.log(error)
          });
},[]);

function openModalCandidate() {
  setIsOpenCandidate(true);
}
function closeModalCandidate() {
  setIsOpenCandidate(false);
}
function openModalMarks() {
  setIsOpenMarks(true);
}
function closeModalMarks() {
  setIsOpenMarks(false);
}

const blockData=useSelector((state)=>{
    return state.interviewerFormEdit.blocks
})
const templateName=useSelector((state)=>{
  return state.interviewerFormEdit.templateName
})
const rows=blockData.map((rowData,index) => {
    console.log(index,rowData)
          return(
              <InterviewerFormRowEdit key={index} index={index} data={rowData}/>
          )
  })

return(
    <div style={{width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",gap:"1px"}}>
      <div style={{textAlign:"center",width:"100%",display:"flex",justifyContent:"flex-end"}}>
        <div style={{width:"50%",textAlign:"right"}}>
          <h3 style={{color:"#fe414d"}}>{templateName==="" ? "Not Selected":templateName}</h3>
        </div>
        
        <div style={{width:"50%",display:"flex",alignItems:"center",justifyContent:"center",gap:"10px"}}>
          <Button variant="outlined" onClick={openModalCandidate}>Candidate Details</Button>
          <Button variant="outlined" onClick={openModalMarks}>Marks Details</Button>
        </div>
      </div>
      <div>
      <Modal
        isOpen={modalIsOpenCandidate}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModalCandidate}
        styles={{width:"40px",height:"fit-content",padding:"20px"}}
        contentLabel="Example Modal"
        className="Modal"
        >
        <InterviewerFormEditCandidateTable/>
      </Modal>
      <Modal
        isOpen={modalIsOpenMarks}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModalMarks}
        contentLabel="Example Modal"
        className="Modal"
        >
        <InterviewerProficiencyTable/>
      </Modal>
      </div>
      {isLoading && <Loader/>}
      <div className='form-wrapper' style={{maxWidth:"100%",boxShadow:"0px 0px 0px 0px",gap:"0px"}} >
          {!isLoading && 
          <div className='createform' style={{margin:"auto"}}>
            <div style={{display:"flex",flexDirection:"Column",gap:"0px"}}>
              {rows}
            </div>
          </div>
          }
      </div>
      <InterviewerFormSubmitEdit roleData={roleData}/>
    </div>
  )
}
export default InterviewFormEdit;