import { useAuthenticator } from '@aws-amplify/ui-react';
import { TextField } from "@mui/material";
// import "../styles/roleSubroleEditor.css"
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useParams } from "react-router-dom";
import BlackButton from "./BlackButton";
import { useState } from "react";
import axios from "axios";
import Loader from "./Loader";
import { proxy } from "../proxy";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
function RoleEditor(){
    const { user} = useAuthenticator((context) => [context.user]);
    const { role,roleid } = useParams();
    const [currentrole,setCurrentrole]=useState()
    const [isLoading,setIsLoading]=useState(false)
    const history = useHistory()
    async function  senddata(){
        axios.put(`${proxy}/api/role`,{"_id":`${roleid}`,"role":`${currentrole}`}, {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              'Authorization':user.signInUserSession.idToken.jwtToken,

              "Content-Type": "application/json",
              "Access-Control-Allow-Credentials": true,
            },
          }).then((res) => {
                setIsLoading(false);
            //   console.log(res.data);
              alert("Role Edited")
              history.push('/roles')
          }).catch((error)=>{
            setIsLoading(false);
            alert("error")
            console.log(error)
          });
          setIsLoading(true);
    };
    return(
        <div>
            {!isLoading && 
            <div>
                <div className="roles-header">
                    <div className="roles-title-header">
                        <p>Add Your Data here <ArrowDownwardIcon/> </p>
                    </div>
                </div>
                <div className="role-editor-components">
                    <div className="role-editor-component">
                        <div className="role-editor-component-title">
                            <p>Current Role</p>
                        </div>
                        <TextField fullWidth disabled value={role}/>
                    </div>
                    <div className="role-editor-component">
                        <div className="role-editor-component-title">
                            <p>Edit Your Role</p>
                        </div>
                        <TextField fullWidth onChange={(e)=>setCurrentrole(e.target.value)}/>
                    </div>
                    <BlackButton id="role-submit" variant="contained" style={{marginTop:"10px"}} onClick={()=>{
                            document.getElementById('role-submit').disabled=true;
                            senddata()
                            document.getElementById('role-submit').disabled=false;
                    }}> Submit</BlackButton>
                </div>
            </div>}

            {isLoading && <Loader/>}
        
        </div>

    )
}
export default RoleEditor;