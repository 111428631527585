import Box from '@mui/material/Box';
import { useState } from "react";
import {useDispatch} from 'react-redux';
import ClearIcon from '@mui/icons-material/Clear';
import "../styles/TemplateStaticComponents.css";
import { removeSection } from '../features/templateSlice';
import TemplateStaticRowSubSection from './TemplateStaticRowSubSection';
import { TextField } from '@mui/material';
import { updateWeightage } from '../features/templateSlice';
function TemplateStaticRow(props) {
    const dispatch=useDispatch()
    const [marks,setmarks]=useState(props.weightage)
    const handleChangeMarks = (event) => {
        // console.log(event.target.value)
        setmarks(event.target.value);
        dispatch(updateWeightage({"sectionIndex":props.sectionIndex,'weightage':event.target.value}))
      };
    return(
        <tr>
            <td >
                {props.section}
            </td>
            <td style={{display:"flex",flexDirection:"column",gap:"10px",boxSizing:"border-box",pading:"5px"}}>
                {props.subSectionsData.map(({subSectionId,subSectionName},index)=>{
                    return(
                        <TemplateStaticRowSubSection key={index} subSectionName={subSectionName} sectionIndex={props.sectionIndex} subSectionIndex={index}/>
                    )
                })}
            </td>
            <td>
                {props.weightage!==-1 && 
                <Box sx={{ minWidth: "150px",height:"100%" }} >
                    <TextField value={marks} type="number" fullWidth onChange={handleChangeMarks}/>
                </Box>}
            </td>
            <td  className="table-remove-button">

                <ClearIcon style={{width:"25px",marginBottom:"auto"}}  onClick={()=>{
                    dispatch(removeSection({sectionIndex:props.sectionIndex}))
                }}/>
            </td>
        </tr>
    )

}
export default TemplateStaticRow;