import * as React from 'react';
import Confetti from 'react-confetti';
import "./interviewerform.css";

const ReactConfetti = (props) => {
    

return (
        <div className="confetti-container">
            <Confetti run={props.showConfetti} width={1000} height={500}/>
        </div>
        
   
)
}

export default ReactConfetti;