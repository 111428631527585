import { useAuthenticator } from "@aws-amplify/ui-react";
import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@aws-amplify/ui-react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import axios from "axios";
import { proxy } from "../proxy";
import { useEffect } from "react";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { motion } from "framer-motion";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import BlackButton from "./BlackButton";
import { tidy, distinct, filter } from "@tidyjs/tidy";
import { Grid, Tooltip } from "@mui/material";
import InterviewerFormView from "./InterviewerFormView";
import Loader from "./Loader";
import SearchIcon from "@mui/icons-material/Search";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import dayjs from "dayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TablePagination from "@mui/material/TablePagination";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import EditOffIcon from "@mui/icons-material/EditOff";
function InterviewerFormSearch() {
  let date = new Date();
  date.setDate(date.getDate() - 30);

  const { user } = useAuthenticator((context) => [context.user]);
  const [candidateName, setCandidateName] = React.useState(null);
  const [interviewerName, setInterviewerName] = React.useState(null);
  const [role, setRole] = React.useState(null);
  const [isLoading, setisLoading] = React.useState(false);
  const [formData, setFormData] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [displayingData, setDisplayingData] = React.useState([]);
  const [candidates, setCandidates] = React.useState([]);
  const [interviewers, setInterviewers] = React.useState([]);
  const [roles, setRoles] = React.useState([]);
  const [display, setDisplay] = React.useState(false);
  const [toDisplay, setToDisplay] = React.useState(false);
  const [showHeader, setShowHeader] = React.useState(false);
  const [status, setStatus] = React.useState(null);
  const [statuses, setstatuses] = React.useState([
    "DisQualified",
    "Reiteration",
    "Qualified",
    "Ongoing",
  ]);
  const [interviewType, setInterviewType] = React.useState(null);
  const [interviewTypes, setInterviewTypes] = React.useState([
    "Domain",
    "Phone",
  ]);
  const [startValue, setStartValue] = React.useState(date);
  const [endValue, setEndValue] = React.useState(new Date());
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [order, setOrder] = React.useState("ASC");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 8));
    setPage(0);
  };

  function initialiseArrays(data) {
    let candidates = [];
    let interviewers = [];
    let roles = [];
    if (data !== null && data !== undefined) {
      data.map(({ candidateName, interviewerName, role }) => {
        candidates.push(candidateName);
        interviewers.push(interviewerName);
        roles.push(role);
        return 1;
      });
    }

    setCandidates(tidy(candidates, distinct([])));
    setInterviewers(tidy(interviewers, distinct([])));
    setRoles(tidy(roles, distinct([])));
    return true;
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    getDomainSearchFormsResult();
  }, []);

  const getDomainSearchFormsResult = async () => {
    try {
      setisLoading(true);
      const res = await axios
        .post(
          `${proxy}/api/forms/interviewerForm`,
          {
            startDate: startValue,
            endDate: endValue,
            interviewer: user.username,
            profile: user.attributes.profile,
          },
          {
            withCredentials: true,
            headers: {
              Authorization: user.signInUserSession.idToken.jwtToken,
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Credentials": true,
            },
          }
        )
        .then((res) => {
          setData(res.data.result);
          setDisplayingData(res.data.result);
          setisLoading(false);
          initialiseArrays(res.data.result);
          setToDisplay(false);
          setShowHeader(true);
          setDisplay(false);
          setPage(0);
          console.log(res.data.result);
        });
    } catch (error) {
      setisLoading(false);
      console.log("Error getting filtered data.");
    }
  };

  const handleChangeCandidateName = (event, newvalue) => {
    // console.log(newvalue);
    setCandidateName(newvalue);
  };
  const handleChangeInterviewerName = (event, newvalue) => {
    // console.log(newvalue);
    setInterviewerName(newvalue);
  };
  const handleChangeRole = (event, newvalue) => {
    // console.log(newvalue);
    setRole(newvalue);
  };
  const handleChangeStatus = (event, newvalue) => {
    // console.log(newvalue);
    setStatus(newvalue);
  };
  const handleChangeInterviewType = (event, newvalue) => {
    setInterviewType(newvalue);
  };
  // function processDate(date) {
  //   let parts = date.split("/");
  //   return new Date(parts[2], parts[1] - 1, parts[0]);
  // }

  const capitalizeFirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const caps = (str) => {
    return str.split(" ").map(capitalizeFirst).join(" ");
  };

  const setdisplayabledata = () => {
    let datatobedisplayed = data;
    // console.log(candidateName,interviewerName,role)
    console.log(data);
    // console.log(datatobedisplayed)
    if (
      candidateName !== null &&
      candidateName !== "" &&
      candidateName !== undefined
    ) {
      datatobedisplayed = tidy(
        data,
        filter((d) => d.candidateName === candidateName)
      );
    }
    if (
      interviewerName !== null &&
      interviewerName !== "" &&
      interviewerName !== undefined
    ) {
      datatobedisplayed = tidy(
        datatobedisplayed,
        filter((d) => d.interviewerName === interviewerName)
      );
    }
    if (role !== null && role !== "" && role !== undefined) {
      datatobedisplayed = tidy(
        datatobedisplayed,
        filter((d) => d.role === role)
      );
    }
    if (status !== null && status !== "" && status !== undefined) {
      if (status === "DisQualified") {
        datatobedisplayed = tidy(
          datatobedisplayed,
          filter((d) => d.status === "Disqualified")
        );
      } else if (status === "Reiteration") {
        datatobedisplayed = tidy(
          datatobedisplayed,
          filter((d) => d.status === "Reiteration")
        );
      } else if (status === "Qualified") {
        datatobedisplayed = tidy(
          datatobedisplayed,
          filter((d) => d.status === "Qualified")
        );
      } else if (status === "Ongoing") {
        datatobedisplayed = tidy(
          datatobedisplayed,
          filter((d) => d.status === "Ongoing")
        );
      }
    }

    if (
      interviewType !== null &&
      interviewType !== "" &&
      interviewType !== undefined
    ) {
      if (interviewType === "Domain") {
        datatobedisplayed = tidy(
          datatobedisplayed,
          filter((d) => d.interviewType === "interviewType")
        );
      } else if (interviewType === "Phone") {
        datatobedisplayed = tidy(
          datatobedisplayed,
          filter((d) => d.interviewType === "Phone")
        );
      }
    }

    
    setShowHeader(true);
    setDisplayingData(datatobedisplayed);
    initialiseArrays(data);
    setDisplay(false);
    setPage(0);
  };

  

  const sortingNumber = (col) => {
    if (order === "ASC") {
      const sorted = [...displayingData].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setDisplayingData(sorted);
      setOrder("DSC");
    }

    if (order === "DSC") {
      const sorted = [...displayingData].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setDisplayingData(sorted);
      setOrder("ASC");
    }
  };

  return (
    <React.Fragment>
      <div style={{ width: "100%", height: "fit-content", margin: "auto" }}>
        <div
          className="sections-header"
          style={{ height: "50px", background: "#fe414d" }}
        >
          <div className="sections-title-header">
            <p style={{ color: "white" }}>Search Domain Forms</p>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          {!toDisplay && (
            <Tooltip title="Open Filters">
              <motion.div
                initial={{ y: -10 }}
                animate={{ y: 0 }}
                transition={{
                  duration: 1,
                  repeat: Infinity,
                  repeatType: "reverse",
                }}
              >
                <KeyboardDoubleArrowDownIcon
                  onClick={() => setToDisplay(!toDisplay)}
                  style={{
                    color: "#2192FF",
                    cursor: "pointer",
                    fontSize: "35px",
                  }}
                />
              </motion.div>
            </Tooltip>
          )}
          {toDisplay && (
            <Tooltip title="Close Filter">
              <motion.div
                initial={{ y: 0 }}
                animate={{ y: -10 }}
                transition={{
                  duration: 1,
                  repeat: Infinity,
                  repeatType: "reverse",
                }}
              >
                <KeyboardDoubleArrowUpIcon
                  onClick={() => setToDisplay(!toDisplay)}
                  style={{
                    color: "#2192FF",
                    cursor: "pointer",
                    fontSize: "35px",
                  }}
                />
              </motion.div>
            </Tooltip>
          )}
        </div>

        {toDisplay && (
          <motion.div
            key="box"
            initial={{ y: -10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{ type: "spring", duration: 2.5 }}
            className="box"
          >
            <div style={{ padding: "5px", width: "100%", marginLeft: "35%" }}>
              <Grid container spacing={1} style={{}}>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      inputFormat="DD/MM/YYYY"
                      maxDate={new Date()}
                      label="Start Date"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="startDate"
                          sx={{ width: 150, marginRight: 2 }}
                        />
                      )}
                      value={startValue}
                      onChange={(newValue) => {
                        setStartValue(new Date(newValue).toISOString());
                      }}
                    />
                    <DesktopDatePicker
                      inputFormat="DD/MM/YYYY"
                      minDate={startValue}
                      maxDate={new Date()}
                      label="End Date"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="endDate"
                          sx={{ width: 150 }}
                        />
                      )}
                      value={endValue}
                      onChange={(newValue) => {
                        setEndValue(new Date(newValue).toISOString());
                      }}
                    />
                  </LocalizationProvider>
                  <BlackButton
                    style={{ marginLeft: "6%" }}
                    onClick={getDomainSearchFormsResult}
                  >
                    Search
                  </BlackButton>
                </Grid>
              </Grid>
            </div>
          </motion.div>
        )}
      </div>
      {!isLoading && (
        <TableContainer
          component={Paper}
          sx={{ minWidth: 650, maxWidth: "100%", margin: "auto" }}
        >
          <Table
            sx={{ minWidth: 650, maxWidth: "100%" }}
            size="small"
            aria-label="a dense table"
          >
            <TableHead style={{ width: "100%" }}>
              <TableRow>
                <TableCell align="center">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={candidates}
                    sx={{ width: 200 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Candidate" />
                    )}
                    onChange={handleChangeCandidateName}
                  />
                </TableCell>
                <TableCell align="center">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={interviewers}
                    sx={{ width: 200 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Interviewer" />
                    )}
                    onChange={handleChangeInterviewerName}
                  />
                </TableCell>
                <TableCell align="center">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={roles}
                    sx={{ width: 130 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Role" />
                    )}
                    onChange={handleChangeRole}
                  />
                </TableCell>
                <TableCell align="center">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={statuses}
                    sx={{ width: 120 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Status" />
                    )}
                    onChange={handleChangeStatus}
                  />
                </TableCell>

                <TableCell align="left">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={interviewTypes}
                    sx={{ width: 150 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Interview Type" />
                    )}
                    onChange={handleChangeInterviewType}
                  />
                </TableCell>

                {/* <TableCell align="center" style={{ color: "black" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      inputFormat="DD/MM/YYYY"
                      maxDate={new Date()}
                      label="Start Date"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="startDate"
                          sx={{ width: 150, marginRight: 2 }}
                        />
                      )}
                      value={startValue}
                      onChange={(newValue) => {
                        setStartValue(newValue);
                      }}
                    />
                  </LocalizationProvider>
                </TableCell>
                <TableCell align="center" style={{ color: "black" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      inputFormat="DD/MM/YYYY"
                      minDate={startValue}
                      maxDate={new Date()}
                      label="End Date"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="endDate"
                          sx={{ width: 150 }}
                        />
                      )}
                      value={endValue}
                      onChange={(newValue) => {
                        setEndValue(newValue);
                      }}
                    />
                  </LocalizationProvider>
                </TableCell> */}
                <TableCell></TableCell>
                <TableCell align="center">
                  <Button
                    starticon={<SearchIcon />}
                    variant="outlined"
                    size="medium"
                    onClick={setdisplayabledata}
                  >
                    Apply Filters
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {showHeader && (
                <TableRow
                  key={-1}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                      fontSize: "large",
                    },
                  }}
                >
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Candidate Name&nbsp;
                    {/* {order === 'ASC' &&
                                                <Tooltip title="Sort Ascending">
                                                <ArrowUpwardIcon style={{cursor:"pointer"}} onClick={()=> sortingString("candidateName")} />  
                                                </Tooltip>
                                                }
                                                {order === 'DSC' && 
                                                <Tooltip title="Sort Descending">
                                                <ArrowDownwardIcon style={{cursor:"pointer"}} onClick={()=> sortingString("candidateName")}/>
                                                </Tooltip>
                                                } */}
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Interviewer Name
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Evaluated Role
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Interview Date&nbsp;
                    {order === "ASC" && (
                      <Tooltip title="Sort Ascending">
                        <ArrowUpwardIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => sortingNumber("interviewDate")}
                        />
                      </Tooltip>
                    )}
                    {order === "DSC" && (
                      <Tooltip title="Sort Descending">
                        <ArrowDownwardIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => sortingNumber("interviewDate")}
                        />
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Status
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Domain Score&nbsp;
                    {order === "ASC" && (
                      <Tooltip title="Sort Ascending">
                        <ArrowUpwardIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => sortingNumber("totalScore")}
                        />
                      </Tooltip>
                    )}
                    {order === "DSC" && (
                      <Tooltip title="Sort Descending">
                        <ArrowDownwardIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => sortingNumber("totalScore")}
                        />
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    View
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Edit
                  </TableCell>
                </TableRow>
              )}

              {displayingData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((data, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">{data.candidateName}</TableCell>
                    <TableCell align="left">{`${caps(
                      data.interviewerName.replace(".", " ")
                    )}`}</TableCell>
                    <TableCell align="left">{data.role}</TableCell>
                    <TableCell align="left">
                      {new Date(data.interviewDate).toLocaleDateString(
                        "en-us",
                        {
                          weekday: "long",
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        }
                      )}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        color:
                          data.status === "Qualified"
                            ? "green"
                            : data.status === "Reiteration"
                            ? "orange"
                            : "Red",
                      }}
                    >
                      {data.status}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        color:
                          data.status === "Qualified"
                            ? "green"
                            : data.status === "Reiteration"
                            ? "orange"
                            : "Red",
                      }}
                    >
                      {data.totalScore}
                    </TableCell>
                    <TableCell align="left" style={{ cursor: "pointer" }}>
                      {data.formFilledStatus === true && (
                        <Tooltip title="View File">
                          <RemoveRedEyeIcon
                            onClick={() => {
                              setDisplay(false);
                              setisLoading(true);
                              axios
                                .post(
                                  `${proxy}/api/forms`,
                                  { id: data.formId },
                                  {
                                    withCredentials: true,
                                    headers: {
                                      Accept: "application/json",
                                      Authorization:
                                        user.signInUserSession.idToken.jwtToken,

                                      "Content-Type": "application/json",
                                      "Access-Control-Allow-Credentials": true,
                                    },
                                  }
                                )
                                .then((res) => {
                                  setisLoading(false);

                                  //   console.log(res.data);
                                  //   const {section,subSections}=res.data
                                  setFormData(res.data.results);
                                  setDisplay(true);
                                  setisLoading(false);
                                })
                                .catch((error) => {
                                  setisLoading(false);
                                  // console.log(error);
                                });
                              setStatus(null);
                              setCandidateName(null);
                              setInterviewerName(null);
                              setRole(null);
                              setstatuses([
                                "DisQualified",
                                "Reiteration",
                                "Qualified",
                                "Ongoing",
                              ]);
                              setShowHeader(false);
                              setDisplayingData([]);
                            }}
                          />
                        </Tooltip>
                      )}
                      {data.formFilledStatus === false && (
                        <Tooltip title="Cannot View File">
                          <VisibilityOffIcon style={{ color: "#FE414D" }} />
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell>
                      {data.interviewerName === user.username &&
                        data.formFilledStatus === false && (
                          <Link
                            to={`/interviewform/${data.formId}`}
                            style={{ textDecoration: "none", color: "#000" }}
                          >
                            <Tooltip title="Edit File">
                              <EditIcon />
                            </Tooltip>
                          </Link>
                        )}
                      {data.formFilledStatus === true && (
                        <Tooltip title="Cannot Edit">
                          <EditOffIcon
                            style={{ color: "#FE414D", cursor: "pointer" }}
                          />
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {displayingData.length === 0 && (
            <div
              style={{ textAlign: "center", fontSize: "24px", width: "100%" }}
            >
              {display ? "See the Form Below" : "No Results Found"}
            </div>
          )}
          <TablePagination
            rowsPerPageOptions={[5, 8, 10]}
            component="div"
            count={displayingData.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      )}
      {isLoading && <Loader />}
      {display && <InterviewerFormView data={formData} />}
    </React.Fragment>
  );
}
export default InterviewerFormSearch;
