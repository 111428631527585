// import { useAuthenticator } from '@aws-amplify/ui-react';
import {createSlice,createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios";
import { proxy } from "../proxy";
let initialState={
    
        candidateName:"",
        interviewerName:"",
        role:"",
        gender:"",
        templateId:"",
        templateName:"",
        preferredRole:"",
        status:"",
        totalScore:"",
        blocks:[],
        isLoading:false,
}



const interviewerFormEditSlice=createSlice({
    name:'interviewerFormEditSlice',
    initialState,
    reducers:{
        updateMarks:(state,action)=>{
            console.log("updateMarks");
            state.blocks[action.payload.index].marks=action.payload.marks;
        },
        updateRemarks:(state,action)=>{
            state.blocks[action.payload.index].remarks=action.payload.remarks;
        },
        initialiseState:(state,action)=>{
            console.log(action.payload,"action")
            state.candidateName=action.payload.candidateName;
            state.interviewerName=action.payload.interviewerName;
            state.role=action.payload.role;
            state.gender=action.payload.gender;
            state.formId=action.payload.formId;
            state.templateName=action.payload.templateName;
            state.preferredRole=action.payload.preferredRole;
            state.status=action.payload.status;
            state.totalScore=action.payload.totalScore;
            state.blocks=action.payload.blocks;
            console.log("state",state.blocks[0].remarks);
        },
        setToInitialState:(state,action)=>{
            state=initialState;
        }
    },
});

export const {updateMarks,updateRemarks,initialiseState,setToInitialState}=interviewerFormEditSlice.actions
export default interviewerFormEditSlice.reducer