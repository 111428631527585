import * as React from 'react';
import InterviewFormSectionNav from './InterviewFormSectionNav';
import '../../styles/CreateFormRow.css';
import { useParams } from 'react-router-dom';

export default function InterviewForm(){
    const { formid } = useParams();
    return (
        <div style={{paddingTop:'20px'}}>
            <InterviewFormSectionNav formid = { formid } />
        </div>
    )
}