import React from 'react';
import '../styles/CreateFormColumn.css';
function InterviewerFormColumn(props){
    // console.log("column",props.expectations)
    const expectationTiles=props.expectations.map((expectation,index)=>{
        return (
            <li key={index}>
                {expectation}
            </li>
        )
    })
    

    return(
        <div className='create-form-column' style={{width:"250px"}}>
            <div className='create-form-column-heading-box'>
                <p style={{color: props.isPresent ? "#000":"#fe414d"}}>{props.nameOfColumn}</p>
            </div>
            {props.isPresent && 
            <div className='create-form-column-content-box'>
                <ol>
                    {expectationTiles}
                </ol>
                {expectationTiles.length===0 && 
                    <div style={{textAlign:"center",color:"rgb(254, 65, 77)"}}>
                        <h3>No Expectations/ProbeAreas</h3>
                    </div>
                }
            </div>
            }
            {!props.isPresent &&  
                <div className='create-form-column-content-box'>
                    <h3 style={{color:"#fe414d"}}>This Variant is Not created</h3>
                </div>
            }
        </div>
    )
}
export default InterviewerFormColumn;